<template>
<div class=" d-none d-lg-block">
  <div class="row">
    <div class="col-6 mb-4">
      <div class="card me-3 rounded-5 bg-primary" style="height: 100%">
        <router-link class="text-decoration-none text-light" to="/WebSitePage">
          <div class="card-body p-4">
            <h5 class="fw-bolder text-center mb-4">Веб-сайты и платформы</h5>
            <div class="row">
              <div class="col-4">
                <img class="img-fluid" src="../assets/img/servwebsite.webp">
              </div>
              <div class="col-8 card-text ps-4" >
                <p class="m-0">
                  Порадуйте своих клиентов эстетическим внешним видом вашего веб-решения. Мы создаем веб-сайты и платформы с отличной производительностью и красивым дизайном.
                </p> 
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="col-6 mb-4">
      <div class="card ms-3 rounded-5 bg-primary" style="height: 100%">
        <router-link class="text-decoration-none text-light" to="/MobileDevPage">
          <div class="card-body p-4">
            <h5 class="fw-bolder text-center mb-4">Мобильные приложения</h5>
            <div class="row">
              <div class="col-4">
                <img class="img-fluid" src="../assets/img/servmobileapp.webp">
              </div>
              <div class="col-8 card-text ps-4" >
                <p class="m-0">
                  Мы создаем мобильные приложения с новейшими технологиями для Android, iOS или кроссплатформы под ключ. Проанализируем цели и задачи бизнеса, и предложим решение, учитывающее ваши индивидуальные требования.
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mb-4">
      <div class="card me-3 rounded-5 bg-primary" style="height: 100%">
        <router-link class="text-decoration-none text-light" to="/UxUiPage">
          <div class="card-body p-4">
            <h5 class="fw-bolder text-center mb-4">UX/UI дизайн</h5>
            <div class="row">
              <div class="col-4">
                <img class="img-fluid" src="../assets/img/servuxui.webp">
              </div>
              <div class="col-8 card-text ps-4" >
                <p class="m-0">
                  Проектирование удобных, понятных и эстетичных пользовательских интерфейсов. Сделаем продукт удобным, понятным и логичным для всех пользователей.
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="col-6 mb-4">
      <div class="card ms-3 rounded-5 bg-primary" style="height: 100%">
        <router-link class="text-decoration-none text-light" to="/Dev1CPage">
          <div class="card-body p-4">
            <h5 class="fw-bolder text-center mb-4">Внедрение и Сопровождение продуктов 1С</h5>
            <div class="row">
              <div class="col-4">
                <img class="img-fluid" src="../assets/img/servproducts.webp">
              </div>
              <div class="col-8 card-text ps-4" >
                <p class="m-0">
                  Обследование Ваших бизнес-процессов. Внедрение под ключ. Сопровождение и обновление до актуальных версий любых программных продуктов фирмы 1С.
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</div>

<div class="d-lg-none row">
  <swiper 
    :slides-per-view="1.01"
    :centered-slides="true"
    :space-between="50"
    :modules="[Autoplay]"
    loop
    Parallax
    :autoplay="{
        delay: 5000,
        disableOnInteraction: false
      }"
    :speed="3000"
    :effect="slide"
  >
    <swiper-slide>
      <div class="col-lg-6 hhh">
        <div class="card me-lg-3 mb-4 rounded-5 bg-primary hhh">
          <router-link class="text-decoration-none text-light hhh" to="/WebSitePage">
            <div class="row m-0 hhh">
              <div class="card-body ps-lg-0 col-lg-8 d-flex justify-content-between flex-column p-4 mt-2 hhh">
                <div class="card-title">
                  <h5 class="fw-bolder">Веб-сайты и платформы</h5>
                </div>
                <div class="card-text mt-3">
                  <p class="m-0">Порадуйте своих клиентов эстетическим внешним видом вашего веб-решения. Мы создаем веб-сайты и платформы с отличной производительностью и красивым дизайном</p> 
                </div>
                <div class="text-center mt-3">
                  <img class="img-fluid col-7 d-lg-none" src="../assets/img/servwebsite.webp">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="col-lg-6 hhh">
        <div class="card ms-lg-3 mb-4 rounded-5 bg-primary hhh">
          <router-link class="text-decoration-none text-light hhh" to="/MobileDevPage">
            <div class="row m-0 hhh">
              <div class="card-body ps-lg-0 col-lg-8 d-flex justify-content-between flex-column p-4 mt-2 hhh">
                <div class="card-title">
                  <h5 class="fw-bolder">Мобильные приложения</h5>
                </div>
                <div class="card-text mt-3">
                  Доведите свой бизнес до клиентов в пределах досягаемости. Мы создаем мобильные приложения с новейшими технологиями, чтобы ваша пользовательская база
                </div>
                <div class="text-center mt-3">
                  <img class="img-fluid col-7 d-lg-none" src="../assets/img/servmobileapp.webp" alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="col-lg-6 hhh">
        <div class="card me-lg-3 mb-4 rounded-5 bg-primary hhh">
          <router-link class="text-decoration-none text-light hhh" to="/UxUiPage">
            <div class="row m-0 hhh">
              <div class="card-body ps-lg-0 col-lg-8 d-flex justify-content-between flex-column p-4 pt-4 hhh">
                <div class="card-title">
                  <h5 class="fw-bolder">UX/UI дизайн</h5>
                </div>
                <div class="card-text mt-3">
                  Обратите внимание на свой бренд с помощью удобного дизайна, который выглядит совершенно правильно. Мы выполняем весь процесс UХ/UI 
                </div>
                <div class="text-center mt-3">
                  <img class="img-fluid col-7 d-lg-none" src="../assets/img/servuxui.webp" alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="col-lg-6 hhh">
        <div class="card ms-lg-3 mb-4 rounded-5 bg-primary hhh">
          <router-link class="text-decoration-none text-light hhh" to="/Dev1CPage">
            <div class="row m-0 hhh">
              <div class="card-body ps-lg-0 col-lg-8 d-flex justify-content-between flex-column p-4 mt-2 hhh">
                <div class="card-title">
                  <h5 class="fw-bolder">Внедрение и Сопровождение продуктов 1С</h5>
                </div>
                <div class="card-text mt-3">
                  Обследование Ваших бизнес-процессов Рекомендации программных продуктов "1С" согласно требованием бизнеса Внедрение под ключ Сопровождение и обновление до актуальных версий любых программных продуктов фирмы 1С
                </div>
                <div class="text-center mt-3">
                  <img class="img-fluid col-7 d-lg-none" src="../assets/img/servproducts.webp" alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</div>

</template>

<style scoped>
.card{
  box-shadow: 0px -1px 19px rgba(0, 123, 127, 0.25);
}
</style>
<script>
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';

export default{
  name: 'home-page',
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
      return {
        Autoplay,
      };
    },
  data() {
    return {
      
    }
  }
}
</script>
