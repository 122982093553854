<template>
<div class="">
<main class="d-flex align-items-center flex-column">
<HeaderMenu />
<section class="row justify-content-center">
  <div class="col-10 col-xl-9 row">

    <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
      <h4 class="mb-5 mb-lg-4"></h4>
      <div>
        <h1 class="display-6 fw-bolder mt-5 mt-lg-0">
          Разработка, Внедрение и 
          Сопровождение  продуктов 1С
        </h1>
      </div>
      <div class="my-5">
        <h4 class="">
          Сопровождение 1С от компании «Gosu» – это комплекс услуг, оказываемых на регулярной основе, и предназначенных для поддержания и развития существующей системы автоматизации.
        </h4>
      </div>
    </div>
    <div class="col-5 fullHeight d-none d-lg-flex">
      <img class="img-fluid" src="../assets/img/dev1cimg1.webp" alt="">
    </div>

    <div class="d-flex justify-content-between text-uppercase mb-5 my-lg-5 py-lg-5">
      <div class="me-5 me-lg-0">
        <p class="display-2 fw-bold">10+</p>
        <h5>опыта</h5>
      </div>
      <div class="me-5 me-lg-0">
        <p class="display-2 fw-bold">5</p>
        <h5>стран</h5>
      </div>
      <div class="me-5 me-lg-0">
        <p class="display-2 fw-bold">50+</p>
        <h5>специалистов</h5>
      </div>
      <div class="me-5 me-lg-0">
        <p class="display-2 fw-bold">10 000+</p>
        <h5>успешных внедрений</h5>
      </div>
    </div>
    
  </div>
  <div class="row justify-content-center" id="gradimg">
    <div class="col-10 col-xl-9 row">

      <div class="col-lg-6 my-5 py-lg-5 tcenter">
        <h3 class="mb-4 fw-bolder text-uppercase">1С Услуги GOSU:</h3>
        <div class="row align-items-center text-start">
          <i class="bi bi-check2 h1 text-dark col-1"></i><h4 class="col-11">Продажа 1С продуктов</h4>
          <i class="bi bi-check2 h1 text-dark col-1"></i><h4 class="col-11">Сопровождение 1С</h4>
          <i class="bi bi-check2 h1 text-dark col-1"></i><h4 class="col-11">Обучение 1С</h4>
          <i class="bi bi-check2 h1 text-dark col-1"></i><h4 class="col-11">Автоматизация бизнеса</h4>
        </div>
      </div>

      <div class="col-lg-6 my-5 py-lg-5 text-end tcenter">
        <img class="img-fluid col-8" src="../assets/img/dev1cimg2.webp" alt="">
      </div>
      
    </div>
  </div>
  <div class="col-10 col-xl-9 row">
    <div class="my-lg-5 py-lg-4"></div>
    <div class="border-end border-primary border-5 text-end py-2 pe-4 pe-lg-5 my-5 my-lg-0">
      <h4 class="text-uppercase fw-bold">
        Стоимость
      </h4>
    </div>
    <div class="my-lg-5"></div>
    <div class="col-lg-4 d-flex flex-column justify-content-center">
      <div class="col-lg-5">
        <h4 class="mb-4">Лицензии </h4>
        <h4 class="mb-4">Установки </h4>
        <h4 class="mb-4">Настройки </h4>
        <h4 class="mb-4">Выгрузки</h4>
      </div>
    </div>
    <div class="col-lg-8 card p-2">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Продукт</th>
            <th scope="col">Кол-во</th>
            <th scope="col">Цена</th>
            <th scope="col">Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Розница для Казахстана. Эл. поставка</th>
            <td>1</td>
            <td>64 800</td>
            <td>64 800</td>
          </tr>
          <tr>
            <th scope="row">Бухгалтерия для Казахстана. Эл поставка</th>
            <td>1</td>
            <td>64 800</td>
            <td>64 800</td>
          </tr>
          <tr>
            <th scope="row">Управление торговлей для Казахстана. Эл поставка</th>
            <td>1</td>
            <td>90 720</td>
            <td>90 720</td>
          </tr>
          <tr>
            <th scope="row">Web касса 1 лицензия на год</th>
            <td>1</td>
            <td>29 500</td>
            <td>29 500</td>
          </tr>
          <tr>
            <th scope="row" class="ps-5">- Клиентская лицезия 1 раб. место</th>
            <td>1</td>
            <td>32 400</td>
            <td>32 400</td>
          </tr>
          <tr>
            <th scope="row" class="ps-5">- Клиентская лицезия 5 раб. Мест</th>
            <td>1</td>
            <td>112 320</td>
            <td>112 320</td>
          </tr>
          <tr>
            <th scope="row" class="ps-5">- Клиентская лицезия 10 раб. Мест</th>
            <td>1</td>
            <td>246 000</td>
            <td>246 000</td>
          </tr>
          <tr>
            <th scope="row" class="ps-5">- Клиентская лицезия 20 раб. Мест</th>
            <td>1</td>
            <td>410 400</td>
            <td>410 400</td>
          </tr>
          <tr>
            <th scope="row" class="ps-5">- Клиентская лицезия 50 раб. Мест</th>
            <td>1</td>
            <td>993 600</td>
            <td>993 600</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-5 py-lg-4"></div>
    <div class="col-lg-6">
      <img class="img-fluid" src="../assets/img/dev1cimg3.webp" alt="">
    </div>
    <div class="col-lg-6 text-end d-grid align-content-center ps-lg-5 mt-5 mt-lg-0">
      <div class="border-end border-primary border-5 mb-5 py-2 pe-4 pe-lg-5">
        <h4 class="text-uppercase fw-bolder">Почему 90% бизнеса<br /> используют 1С?</h4> 
      </div>
      <div class="ps-lg-5">
        <h6 class="ps-lg-5 ms-lg-5">
          Самой распространенной программной такого типа является #1С:Предприятие. 
          <br /><br />
          Главная причина массового использования данного ПО его универсальность и возможность применения в любой сфере деятельности. 1С может быть адаптировано, как для автоматизации ресторанного или любого малого бизнеса, так и для учета деятельности металлургического завода.⁣⁣⠀
        </h6>
      </div>
    </div>
    <div class="my-lg-5"></div>
  </div>
</section>
<PartnersVue />

<GeoForm />

<QuestionVue />

<!-- <footer class="mt-5 pt-5"> -->
<FooterVue />
</main>
</div>
</template>

<script>

import HeaderMenu from '@/components/HeaderMenu.vue'
// import Services from '@/components/ServicesVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name: 'product-page',
  components:{
    HeaderMenu,
    // Services,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  },
}
</script>
<style scoped>
#gradimg{
  background: linear-gradient(90deg, rgb(0, 0, 0, 0) 0%, rgba(101, 178, 240) 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
