<template>
<div class="">
<HeaderMenu />
<main class="d-flex align-items-center flex-column">
<section class="row justify-content-center">
  <div class="col-10 col-xl-9 row">

    <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
      <h4 class="mb-5 mb-lg-4"></h4>
      <div>
        <h1 class="display-3 fw-bolder my-5 my-lg-0">
          UX/UI дизайн
        </h1>
      </div>
      <div class="my-lg-5 mb-5">
        <h4 class="">
          Создаём удобные, эргономичные интерфейсы согласно бизнес-требованиям, разрабатываем дизайн-концепты. Грамотно выстраиваем интерфейс, расставляем акценты и разрабатываем актуальное стилевое решение, учитывая предпочтения целевой аудитории и задачи проекта.
        </h4>
      </div>
    </div>
    <div class="col-5 fullHeight d-none d-lg-flex">
      <img class="img-fluid" src="../assets/img/uxuiimg1.webp" alt="">
    </div>
    <div class="my-lg-5"></div>

    <div class="border-start border-5 border-primary ps-4 ps-lg-5">
      <h4 class="text-uppercase fw-bolder my-2">
        этапы разработки
      </h4>
    </div>
    <div class="text-center py-5 mb-lg-5">
      <img class="img-fluid col-lg-7" src="../assets/img/wsimg2.webp" alt="">
    </div>
    
    <div class="col-lg-6 d-flex flex-column justify-content-center mb-lg-5 boximg">
      <div class="py-5 ps-4">
        <h3 class="mb-4">Исследование </h3>
        <h3 class="mb-4">Разработка прототипов</h3>
        <h3 class="mb-4">Разрабатываем дизайн</h3>
        <h3 class="mb-4">Тестирование</h3>
      </div>  
    </div>
    <div class="col-lg-6 mb-5 d-none d-lg-block"> 
        <img 
            class="img-fluid" 
            src="../assets/img/uxuiimg2.webp" 
            alt=""
        >
    </div>

    <div class="my-lg-5 py-lg-4"></div>

    <div class="border-end border-primary border-5 text-end py-2 pe-5 my-5 my-lg-0">
      <h4 class="text-uppercase fw-bold">
        мы поможем вам с выбором сайта
      </h4>
    </div>
    <div class="my-lg-5"></div>
    <div class="row justify-content-between">
      <div class="col-lg-5 mb-5">
        <div class="tcenter">
          <img src="../assets/img/uxuiimg3.webp" class="img-fluid col-9 mb-5">
        </div>
        <h4 class="fw-bold text-uppercase mb-4 tcenter">Одностраничный сайт</h4>
        <div class="row  align-items-center">
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Предлогает один конкретный товар (или услугу)</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Хорошо побуждает клиентов к действию.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Отличается быстрым темпом разработки.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Большое пространство для креативного дизайна.</h6>
        </div>
      </div>
      <div class="col-lg-5 mb-lg-5">
        <div class="tcenter">
          <img src="../assets/img/uxuiimg4.webp" class="img-fluid col-9 mb-5">
        </div>
        <h4 class="fw-bold text-uppercase mb-4 tcenter">Сайт-визитка</h4>
        <div class="row  align-items-center">
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Предназначен для рекламы компании и ее услуг.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Полноценный сайт для бизнеса, хоть и небольшой.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Отлично повышает узнаваемость предприятия.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Формирует позитивный имидж молодой компании.</h6>
        </div>
      </div>
      <div class="col-lg-5 mt-5">
        <div class="tcenter">
          <img src="../assets/img/uxuiimg5.webp" class="img-fluid col-9 mb-5">
        </div>
        <h4 class="fw-bold text-uppercase mb-4 tcenter">Интернет-магазин</h4>
        <div class="row  align-items-center">
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Позволяет управлять ассортиментом товаров.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Имеет интегрированную систему оплаты.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Предоставляет развернутую информацию о товарах.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Автоматизирован для работы 24/7.</h6>
        </div>
      </div>
      <div class="col-lg-5 mt-5">
        <div class="tcenter">
          <img src="../assets/img/uxuiimg6.webp" class="img-fluid col-9 mb-5">
        </div>
        <h4 class="fw-bold text-uppercase mb-4 tcenter">Веб-портал</h4>
        <div class="row  align-items-center">
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Отличается высокой интерактивностью.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Поддерживает групповую работу пользователей.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Персонализирует доступ к различным сервисам.</h6>
          <i class="col-1 h3 bi bi-check2 text-primary"></i><h6 class="col-11">Включает в себя обширную базу данных.</h6>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnersVue />

<GeoForm />

<QuestionVue />

<!-- <footer class="mt-5 pt-5"> -->
<FooterVue />
</main>
</div>
</template>

<script>

import HeaderMenu from '@/components/HeaderMenu.vue'
// import Services from '@/components/ServicesVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name: 'product-page',
  components:{
    HeaderMenu,
    // Services,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  },
}
</script>
<style scoped>
#gradimg{
  background-image: url(../assets/img/dev1cimg2.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
@media(max-width:500px){
  .boximg{
    background-image: url('../assets/img/uxuiimg2.webp');
    background-size: cover;
  }
}
</style>
