<template>
<div class="">
<main class="d-flex align-items-center flex-column">
<HeaderMenu />
<section class="row justify-content-center">
  <div class="col-10 col-xl-9 row p-0">

    <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
      <h4 class="mb-5 mb-lg-4"></h4>
      <div>
        <h1 class="display-3 fw-bolder my-5 my-lg-0">Мобильная разработка</h1>
      </div>
      <div class="my-lg-5 mb-5 pe-5">
        <h4 class="">
          Доведите свой бизнес до клиентов в пределах досягаемости. Мы создаем мобильные приложения с новейшими технологиями, чтобы ваша пользовательская база
        </h4>
      </div>
    </div>
    <div class="col-5 fullHeight d-none d-lg-flex">
      <img class="img-fluid" src="../assets/img/mobdevimg1.webp" alt="">
    </div>

    <div class="my-lg-5 py-lg-4"></div>

    <div class="border-start border-5 border-primary ps-4 ps-lg-5 py-2">
      <h4 class="text-uppercase fw-bolder">
        Порядок работы
      </h4>
    </div>
    <div class="text-center py-5 mb-lg-5">
        <img class="img-fluid col-lg-7" src="../assets/img/wsimg2.webp" alt="">
    </div>
    <div class="col-lg-6 d-grid align-items-center mb-lg-5 mdimg">
      <div class="py-5 ps-4 ps-lg-0 py-5">
        <h3>Подписание договора</h3>
        <h3>Составление ТЗ</h3>
        <h3>Разработка и утверждение дизайна</h3>
        <h3>Этап разработки</h3>
        <h3>Публикация в App Store/Google Play</h3>
      </div>
    </div>
    <div class="col-6 mb-5 d-none d-lg-block"> 
        <img 
            class="img-fluid" 
            src="../assets/img/wsimg3.webp" 
            alt=""
        >
    </div>
    <div class="my-lg-5"></div>
    <div class="border-end border-5 border-primary text-end pe-4 pe-lg-5 my-5 py-2">
      <h4 class="text-uppercase fw-bolder">
        Стоимость
      </h4>
    </div>
    <div class="d-flex justify-content-center">
      <div class="col-xl-10 bg-light card py-5 text-dark">
        <div class="row">
          <div class="col-lg-6 p-0">
            <div class="tcenter">
              <img src="../assets/img/logoOrigBP.webp" class="col-2 col-lg-3 mb-4 mb-lg-5 ps-lg-5">
            </div>
            <div class="tcenter ps-lg-5">
              <h1 class="fw-bold display-5 me-3">Мобильное <p class="fw-bold display-5 text-primary d-inline">приложение</p></h1>
            </div>
            <div class="row align-items-center mt-4 px-4 d-lg-none">
              <h6 class="">Разработка мобильных приложений любой сложности. Наши разработчики используют новейшие технологии, фреймворки и компоненты для создания решений, которые сочетают в себе мощную техническую поддержку с простым в использовании современным дизайном.</h6>
            </div>
            <div class="price text-light text-center py-2">
              <h6 class="display-6 fw-bold my-4 py-2 my-lg-5 ps-4">от 1 000 000 ₸</h6>
            </div>
            <div class="row d-none d-lg-flex">
              <div class="col-3"></div>
              <!-- <p class="fw-bold col-6 ms-3">+бесплатная настройка для типовых конфигураций</p> -->
            </div>
            <div class="mt-4 ps-lg-5 tcenter">
              <a href="https://api.whatsapp.com/send?phone=77788181168" target="_blank">
                <button class="btn btn-outline-primary px-5 py-3">
                  <h6 class="fw-bold text-uppercase m-0 mx-2">приобрести</h6> 
                </button>
              </a>
            </div>
          </div>
          <div class="col-6 row align-items-center pe-5 d-none d-lg-flex">
            <h4 class="">Разработка мобильных приложений любой сложности. Наши разработчики используют новейшие технологии, фреймворки и компоненты для создания решений, которые сочетают в себе мощную техническую поддержку с простым в использовании современным дизайном.</h4>
          </div>
        </div>
      </div>
    </div>
    
    
    <div class="my-lg-5"></div>
    <div class="border-start border-primary border-5 ps-4 ps-lg-5 my-5 py-2">
      <h4 class="fw-bold">ЗАЧЕМ МОБИЛЬНОЕ ПРИЛОЖЕНИЕ БИЗНЕСУ?</h4> 
    </div>
    <div class="row justify-content-between">
      <div class="col text-center">
        <img src="../assets/img/icon/icws1.webp" class="img-fluid px-4" alt="">
        <h6 class="fw-bold mt-3">УВЕДОМЛЕНИЕ КЛИЕНТОВ</h6>
      </div>
      <div class="col text-center">
        <img src="../assets/img/icon/icws2.webp" class="img-fluid px-4" alt="">
        <h6 class="fw-bold mt-3">УСКОРЕНИЕ ПРИНЯТИЯ РЕШЕНИЙ</h6>
      </div>
      <div class="col text-center">
        <img src="../assets/img/icon/icws3.webp" class="img-fluid px-4" alt="">
        <h6 class="fw-bold mt-3">ПОВЫШЕНИЕ <br />ЛОЯЛЬНОСТИ КЛИЕНТОВ</h6>
      </div>
      <div class="col text-center">
        <img src="../assets/img/icon/icws4.webp" class="img-fluid px-4" alt="">
        <h6 class="fw-bold mt-3">ДОПОЛНИТЕЛЬНЫЕ  ПРОДАЖИ</h6>
      </div>
      <div class="col text-center">
        <img src="../assets/img/icon/icws5.webp" class="img-fluid px-4" alt="">
        <h6 class="fw-bold mt-3">АВТОМАТИЗАЦИЯ ПРОЦЕССОВ</h6>
      </div>
    </div>
    <div class="my-lg-5"></div>
  </div>
</section>
<PartnersVue />

<GeoForm />

<QuestionVue />

<!-- <footer class="mt-5 pt-5"> -->
<FooterVue />
</main>
</div>
</template>

<script>

import HeaderMenu from '@/components/HeaderMenu.vue'
// import Services from '@/components/ServicesVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name: 'product-page',
  components:{
    HeaderMenu,
    // Services,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  },
}
</script>
<style scoped>
.price{
  background-image: url(../assets/img/elemPricePrimary.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}
@media(max-width:500px){
  .mdimg{
    background-image: url('../assets/img/wsimg3.webp');
    background-size: cover;
  }
}
</style>
