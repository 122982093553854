<template>
<div class="">

<main class="d-flex align-items-center flex-column">
<HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0 position-relative">

      <div class="col-lg-6 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-5 mb-lg-4"></h4>
        <div>
          <h1 class="display-3 fw-bold mt-5 mt-lg-0">Наш подход</h1>
        </div>
        <div class="my-5">
          <h4 class="pe-lg-3">
            Подход к решению проблем с практическим, ориентированным на пользователя мышлением приводит к инновациям, а инновации могут привести к дифференциации и конкурентному преимуществу.
          </h4>
        </div>
      </div>
      <div class="col-3 fullHeight d-none d-lg-flex">
        <img src="../assets/img/workimg1.webp" class="img-fluid position-absolute start-50 col-8">
      </div>
      <div class="my-lg-5"></div>

      <div class="row justify-content-between mb-5 py-lg-5 p-0">
        <div class="col-lg-6 mb-lg-5">
          <h4 class="text-uppercase fw-bold border-start border-primary border-5 py-2 ps-4 ps-lg-5">
            Дизайн - мышление
          </h4> 

          <h5 class="fw-normal ps-2 ps-lg-3 my-4 py-2 bg-primary bg-opacity-25 rounded-pill">
            Вот как мы понимаем проблемы
          </h5>
          <div class="my-5 box d-flex flex-column justify-content-center d-lg-none py-5">
            <div class="row justify-content-center mt-3">
              <div class="col-4 text-center">
                <img class="img-fluid col-3 mb-2" src="../assets/img/icon/ic1.webp" alt="">
                <h6>
                  Осуществлять
                </h6>
              </div>
              <div class="col-4 text-center">
                <img class="img-fluid col-3 mb-2" src="../assets/img/icon/ic2.webp" alt="">
                <h6>
                  Сопереживать
                </h6>
              </div>
            </div>
            <div class="row justify-content-center my-3">
              <div class="col-4 text-center">
                <img class="img-fluid col-3 mb-2" src="../assets/img/icon/ic3.webp" alt="">
                <h6>
                  Тестирование
                </h6>
              </div>
              <div class="col-2"></div>
              <div class="col-4 text-center">
                <img class="img-fluid col-3 mb-2" src="../assets/img/icon/ic4.webp" alt="">
                <h6>
                  Определять
                </h6>
              </div>
            </div>
            <div class="row justify-content-center mb-3">
              <div class="col-4 text-center">
                <img class="img-fluid col-3 mb-2" src="../assets/img/icon/ic5.webp" alt="">
                <h6>
                  Прототип
                </h6>
              </div>
              <div class="col-4 text-center">
                <img class="img-fluid col-3 mb-2" src="../assets/img/icon/ic6.webp" alt="">
                <h6>
                  Представлять
                </h6>
              </div>
            </div>
          </div>
          <h5 class="fw-normal">
            Каждая проблема – это задача, которую нужно решить. Мы примвеняем ориентированный на человека подход к творческому решению проблем, чтобы объединить потребности клиентов, технологические инновации и бизнес-цели.
            <br /><br />
            Удержание пользователя в центре ваших продуктов приводит к разработке цифровых решений, которые желательны на рынке, технологически продвинуты и экономически жизнеспособны.
            <br /><br />
            Дизайн-мышление — это новый способ видения мира, который позволяет брендам прислушиваться к своим клиентам, создавая востребованные всемирно известные цифровые продукты.
            <br /><br />
          </h5>
        </div>
        <div class="col-6 col-xl-5 mb-5 box d-lg-flex flex-column justify-content-center d-none">
          <div class="row justify-content-center my-3">
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic1.webp" alt="">
              <h6>
                Осуществлять
              </h6>
            </div>
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic2.webp" alt="">
              <h6>
                Сопереживать
              </h6>
            </div>
          </div>
          <div class="row justify-content-center my-3">
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic3.webp" alt="">
              <h6>
                Тестирование
              </h6>
            </div>
            <div class="col-2"></div>
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic4.webp" alt="">
              <h6>
                Определять
              </h6>
            </div>
          </div>
          <div class="row justify-content-center my-3">
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic5.webp" alt="">
              <h6>
                Прототип
              </h6>
            </div>
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic6.webp" alt="">
              <h6>
                Представлять
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic2.webp" alt="">
              </div>
            </div>
            <div class="col-lg-7 tcenter px-2 px-lg-0">
              <h4 class="mb-4 fw-bold">Сопереживать</h4>
              <h5>
                Проведение исследований для развития понимания пользователей.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic4.webp" alt="">
              </div>
            </div>
            <div class="col-lg-9 tcenter px-2 px-lg-0">
              <h4 class="mb-4 fw-bold">Определять</h4>
              <h5>
                Объединение и анализ исследований, чтобы определить, где существуют проблемы пользователей.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic6.webp" alt="">
              </div>
            </div>
            <div class="col-lg-7 tcenter px-2 px-lg-0">
              <h4 class="mb-4 fw-bold">Представлять</h4>
              <h5>
                Мыслить нестандартно, чтобы генерировать ряд творческих идей для решения этого вопроса.
              </h5>
            </div>

          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic5.webp" alt="">
              </div>
            </div>
            <div class="col-lg-9 tcenter px-2 px-lg-0">
              <h4 class="mb-4 fw-bold">Прототип</h4>
              <h5>
                Построение реальных, тактильных представлений для целого ряда творческих идей.
              </h5>
            </div>

          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic3.webp" alt="">
              </div>
            </div>
            <div class="col-lg-7 tcenter px-2 px-lg-0 pe-0">
              <h4 class="mb-4 fw-bold">Тестировать</h4>
              <h5>
                Возвращение к пользователям для обратной связи, чтобы получить окончательное понимание их поведения.
              </h5>
            </div>

          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic1.webp" alt="">
              </div>
            </div>
            <div class="col-lg-9 tcenter px-2 px-lg-0">
              <h4 class="mb-4 fw-bold">Осущетствлять</h4>
              <h5>
                Реализация отточенного видения для представления продукта, ориентированного на клиента.
              </h5>
            </div>

          </div>
        </div>
      </div>
      <div class="py-lg-5"></div>
      <div class="row justify-content-between mb-5 py-lg-5 p-0">
        <div class="col-6 col-xl-5 my-5 box d-none d-lg-flex flex-column justify-content-center py-5">
          <div class="row justify-content-center">
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic3.webp" alt="">
              <h6>
                Тестирование
              </h6>
            </div>
            
          </div>
          <div class="row justify-content-center my-3">
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic7.webp" alt="">
              <h6>
                Деплой
              </h6>
            </div>
            <div class="col-2"></div>
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic8.webp" alt="">
              <h6>
                Разработка
              </h6>
            </div>
          </div>
          <div class="row justify-content-center my-3">
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic9.webp" alt="">
              <h6>
                Обзор
              </h6>
            </div>
            <div class="col-4 text-center">
              <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic10.webp" alt="">
              <h6>
                Дизайн
              </h6>
            </div>
          </div>
        </div>
        <div class="col-lg-6 text-end mb-lg-5 py-lg-3">
          <h4 class="text-uppercase fw-bold border-end border-primary border-5 py-2 pe-4 pe-lg-5">
            Мы гибкие
          </h4> 

          <h5 class="fw-normal ps-3 py-2 my-4 pe-3 bg-primary bg-opacity-25 rounded-pill">
            Вот как мы поставляем решения
          </h5>

          <div class="col-lg-5 box mb-4 d-flex flex-column justify-content-center py-5 d-lg-none">
            <div class="row justify-content-center">
              <div class="col-4 text-center">
                <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic3.webp" alt="">
                <h6>
                  Тестирование
                </h6>
              </div>
              
            </div>
            <div class="row justify-content-center my-3">
              <div class="col-4 text-center">
                <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic7.webp" alt="">
                <h6>
                  Деплой
                </h6>
              </div>
              <div class="col-2"></div>
              <div class="col-4 text-center">
                <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic8.webp" alt="">
                <h6>
                  Разработка
                </h6>
              </div>
            </div>
            <div class="row justify-content-center my-3">
              <div class="col-4 text-center">
                <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic9.webp" alt="">
                <h6>
                  Обзор
                </h6>
              </div>
              <div class="col-4 text-center">
                <img class="img-fluid col-4 mb-2" src="../assets/img/icon/ic10.webp" alt="">
                <h6>
                  Дизайн
                </h6>
              </div>
            </div>
          </div>
          <h5 class="fw-normal">
            Мы не делаем ставку на запуск «большого взрыва». Мы придерживаемся гибких подходов в нашем рабочем процессе, выполняя работу небольшими этапами. Каждый из них постоянно оценивается, формируя естественный механизм для быстрой адаптации в быстро меняющейся среде.
            <br /><br /><br />
            Он выступает за адаптивное планирование, эволюционное развитие, раннюю доставку и постоянное улучшение цифрового продукта с пикселями.
            <br /><br />
            
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic10.webp" alt="">
              </div>
            </div>
            <div class="col-lg-8 tcenter px-2 px-lg-0 pe-0">
              <h4 class="mb-4 fw-bold">Дизайн</h4>
              <h5>
                Создание архитектуры решения и визуального дизайна для удовлетворения заданных требований.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic8.webp" alt="">
              </div>
            </div>
            <div class="col-lg-9 tcenter px-2 px-lg-0 pe-3">
              <h4 class="mb-4 fw-bold">Разработка</h4>
              <h5>
                Преобразование проектных решений и требований к программному обеспечению в реальный цифровой продукт.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic6.webp" alt="">
              </div>
            </div>
            <div class="col-lg-8 tcenter px-2 px-lg-0 pe-0">
              <h4 class="mb-4 fw-bold">Тестирование</h4>
              <h5>
                Проверка программного обеспечения на отсутствие ошибок и совместимость с ранее разработанными решениями.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic7.webp" alt="">
              </div>
            </div>
            <div class="col-lg-9 tcenter px-2 px-lg-0 pe-3">
              <h4 class="mb-4 fw-bold">Деплой</h4>
              <h5>
                Развертывание программного обеспечения на серверах для демонстрации результатов проекта для клиента.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5 pb-3 px-lg-3">
          <div class="d-lg-flex">
            <div class="col-lg-3">
              <div class="col-3 mb-4 col-lg-7 bg-primary bg-opacity-25 rounded-2 mx-auto p-3">
                <img class="img-fluid" src="../assets/img/icon/ic9.webp" alt="">
              </div>
            </div>
            <div class="col-lg-8 tcenter px-2 px-lg-0 pe-0">
              <h4 class="mb-4 fw-bold">Обзор</h4>
              <h5>
                Оценка хода реализации проекта и принятие решений о дальнейшей итерации разработки.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-5 d-lg-none"></div>
  <section class="position-relative my-5 py-lg-5">
    <div class="col-lg-4 col-xl-3 d-none d-lg-flex position-absolute translate-middle top-50 start-50 d-flex justify-content-center">
      <div class="col-xl-8 text-center">
        <h4 class="fw-bold text-uppercase mb-3">
          Метод работы
        </h4>
        <h5>
          Как мы выведем вас в топ результатов поиска
        </h5>
      </div>
      <img class="img-fluid position-absolute translate-middle top-50 start-50" src="../assets/img/elemVolna.webp" alt="" style="z-index: -2;">
    </div>

    <div class="col-lg-3 d-lg-none position-relative translate-middle top-50 start-50 d-flex justify-content-center my-5">
      <div class="col-lg-8 text-center">
        <h4 class="fw-bold text-uppercase mb-3">
          Метод работы
        </h4>
        <h5>
          Как мы выведем вас в топ результатов поиска
        </h5>
      </div>
      <img class="img-fluid position-absolute translate-middle top-50 start-50" src="../assets/img/elemVolna.webp" alt="" style="z-index: -2;">
    </div>
    <div class="py-5 d-lg-none"></div>
    <div class="row justify-content-center mt-5">
      <div class="col-10 col-lg-5 col-xl-4 position-relative text-center rounded-3 gradientstart pt-5 pb-3 px-3 px-lg-5">
        <div class="col-3 col-lg-2 p-3 position-absolute translate-middle top-0  start-50 bg-primary bg-opacity-25 rounded-3">
          <img src="../assets/img/icon/ic11.webp" alt="" class="img-fluid p-1">
        </div>
        <h5 class="mt-3">
          Мы анализируем конкурентов и предлагаем решения, которые принесут поисковый трафик и увеличат выполнение целевых действий.
        </h5>
      </div>
    </div>
    <div class="row justify-content-between pb-5 d-none d-lg-flex">
      <div class="col-4 position-relative text-center rounded-3 gradientstart pt-5 pb-3 px-5">
        <div class="col-2 p-3 position-absolute translate-middle top-0  start-50 bg-primary bg-opacity-25 rounded-3">
          <img src="../assets/img/icon/ic12.webp" alt="" class="img-fluid p-1">
        </div>
        <h5 class="mt-4">
          Мы грамотно понимаем поставленную задачу и помогаем достичь целей проекта
        </h5>
      </div>
      <div class="col-4 position-relative text-center rounded-3 gradientstart pt-5 pb-3 px-5">
        <div class="col-2 p-3 position-absolute translate-middle top-0  start-50 bg-primary bg-opacity-25 rounded-3">
          <img src="../assets/img/icon/ic13.webp" alt="" class="img-fluid p-1">
        </div>
        <h5 class="mt-4">
          Грамотная оптимизация сайта в соответствии с требованиями поисковых систем.
        </h5>
      </div>
    </div>
    <div class="row justify-content-between pt-5 d-none d-lg-flex">
      <div class="col-4 position-relative text-center rounded-3 gradientstart pt-5 pb-3 px-5">
        <div class="col-2 p-3 position-absolute translate-middle top-0  start-50 bg-primary bg-opacity-25 rounded-3">
          <img src="../assets/img/icon/ic14.webp" alt="" class="img-fluid p-1">
        </div>
        <h5 class="mt-4">
          Мы используем только проверенные тактики без риска для бизнеса
        </h5>
      </div>
      <div class="col-4 position-relative text-center rounded-3 gradientstart pt-5 pb-3 px-5">
        <div class="col-2 p-3 position-absolute translate-middle top-0  start-50 bg-primary bg-opacity-25 rounded-3">
          <img src="../assets/img/icon/ic15.webp" alt="" class="img-fluid p-1">
        </div>
        <h5 class="mt-4">
          Мы постоянно расставляем приоритеты в задачах и выделяем наиболее эффективные
        </h5>
      </div>
    </div>
    <div class="row justify-content-center d-none d-lg-flex">
      <div class="col-5 col-xl-4 position-relative text-center rounded-3 gradientstart pt-5 pb-3 px-5">
        <div class="col-2 p-3 position-absolute translate-middle top-0  start-50 bg-primary bg-opacity-25 rounded-3">
          <img src="../assets/img/icon/ic16.webp" alt="" class="img-fluid p-1">
        </div>
        <h5 class="mt-4">
          Мы контролируем выполнение всех рекомендаций и задач, согласованных в плане работы
        </h5>
      </div>
    </div>
  </section>
  <div class="py-lg-5"></div>
  <div class="container-fluid d-flex justify-content-center py-lg-5 mb-lg-5">
    <section class="row justify-content-center">
      <div class="col-10 col-xl-9 row p-0">
        <div class="col-lg-7 position-relative">
          <img class="img-fluid col-lg-10 " src="../assets/img/wsimg5.webp" alt="">
        </div>
        <div class="col-lg-5 text-end d-grid align-content-center mt-5 mt-lg-0">
          <div class="border-end border-primary border-5 mb-5 mb-lg-4 py-2 pe-4 pe-lg-5">
            <h4 class="text-uppercase fw-bold">Наш фокус</h4> 
          </div>
          <h5 class="ps-4 fw-bold">
            Мы превращаем ваши бизнес-задачи в цифровые продукты «под ключ», будь то пользовательское программное обеспечение, веб-приложение, мобильное приложение или дизайн цифрового решения. Независимо от поставленной задачи, у нас есть команда самых ярких умов, чтобы решить любую бизнес-задачу и создать всемирно известный продукт.
          </h5>
        </div>
      </div>
    </section>
  </div>

  <PartnersVue />
  
  <GeoForm />
    
  <QuestionVue />

  <FooterVue />

</main>
</div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name:'AboutUs',
  components:{
    HeaderMenu,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  }
}
</script>
<style>
.box{
  background-image: url(../assets/img/elemEllipse.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gradientstart{
  background: linear-gradient(90deg, rgba(101, 178, 240, 0.493) 0%, rgba(0, 0, 0, 0) 100%);
}
</style>
