<template>
<section class="row justify-content-center">
  <div class="col-10 col-xl-9 row mb-4 p-0">
    <div class="row col-lg-5 tcenter">
      <div class="d-sm-none d-lg-inline-block col-sm-2 col-lg-4">
        <img src="../assets/img/logoOrigBPC.webp" class="col-2 col-sm-5 col-lg-3 px-2 px-sm-0 me-lg-3" alt="">
        <h3 class="d-none d-lg-inline-block">gosu</h3>
      </div>
      <div class="text-sm-center text-lg-start col-lg-11 mt-4 mt-sm-0 mt-lg-3">
        <h6 class="col-xl-9">
          Мы разрабатываем IT продукт под ключ, который соответствует вашим ожиданиям и целям бизнеса. Помогаем стартапам и компаниям радовать людей по другую сторону экрана. Товарищество с ограниченной ответственностью "Gosu"
        </h6>
      </div>
    </div>
    <div class="col d-none d-lg-block"></div>
    <div class="col-3 d-none d-lg-block">
      <h5 class="fw-bolder mb-3">Компания</h5>
      <div>
        <router-link class="text-dark undrln" to="/AboutUs"><h6>О нас</h6></router-link>
        <router-link class="text-dark undrln" to="/ProductsPage"><h6>Продукты</h6></router-link>
        <router-link class="text-dark undrln" to="/ProjectsPage"><h6>Проекты</h6></router-link>
        <router-link class="text-dark undrln" to="/OurWork"><h6>Как мы работаем?</h6></router-link>
        <router-link class="text-dark undrln" to="/"><h6>Почему gosu?</h6></router-link>
      </div>
    </div>
    <div class="col-3 d-none d-lg-block">
      <h5 class="fw-bolder mb-3">Услуги</h5>
      <div>
        <router-link class="text-dark undrln" to="/WebSitePage"><h6>Веб-сайты и платформы</h6></router-link>
        <router-link class="text-dark undrln" to="/MobileDevPage"><h6>Мобильная разработка</h6></router-link>
        <router-link class="text-dark undrln" to="/Dev1CPage"><h6>1С-разработка</h6></router-link>
        <router-link class="text-dark undrln" to="/UxUiPage"><h6>Ux/Ui дизайн</h6></router-link>
        <router-link class="text-dark undrln" to="/"><h6>Проектирование решений</h6></router-link>
      </div>
    </div>
    <div class="row justify-content-center align-items-center mt-5">
      <h6 class="col-lg d-none d-lg-block m-0">
        <a class="text-dark text-decoration-none"><i class="bi bi-geo-alt"></i> Алматы, Казахстан</a>&emsp;&emsp;&emsp;&emsp;   
        <a class="text-dark text-decoration-none"><i class="bi bi-c-circle"></i> ТОО "Gosu". All Rights Reserved.</a>
      </h6>
      <div class="col-12 col-sm-8 col-lg-4 col-xl-3 d-flex justify-content-between">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=77788181168&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%A5%D0%BE%D1%87%D1%83%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%B5%D0%B5%20%D0%BE%20%D0%B2%D0%B0%D1%88%D0%B8%D1%85%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%B0%D1%85..."><i class="bi bg-dark rounded-pill text-light p-2 h6 bi-whatsapp"></i></a>
        <a target="_blank" href="https://instagram.com/gosulab.kz?igshid=YmMyMTA2M2Y="><i class="bi bg-dark rounded-pill text-light p-2 h6 bi-instagram"></i></a>
        <a target="_blank" href="https://www.youtube.com/@gosu5443"><i class="bi bg-dark rounded-pill text-light p-2 h6 bi-youtube"></i></a>
        <a target="_blank" href="https://www.tiktok.com/@gosu.kz?_t=8Z9NVc77wco&_r=1 "><i class="bi bg-dark rounded-pill text-light p-2 h6 bi-tiktok"></i></a>
      </div>
      <h6 class="d-grid text-center d-lg-none mt-5 mb-4">
        <a class="text-dark text-decoration-none"><i class="bi bi-geo-alt"></i> Алматы, Казахстан</a>&emsp;&emsp;&emsp;&emsp;   
        <a class="text-dark text-decoration-none"><i class="bi bi-c-circle"></i>ТОО "Gosu". All Rights Reserved.</a>
      </h6>
    </div>
  </div>
</section>
</template>

<script>

</script>

<style scoped>
.undrln{
  text-decoration: none;
}
.undrln:hover{
  text-decoration: underline;
}

</style>
