<template>
<div> 
<main class="d-flex align-items-center flex-column overflow-hidden">
<HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0 position-relative">
      
      <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-5 mb-lg-4"></h4>
        <div class="row align-items-center">
          <img class="col-3 me-3" src="../assets/img/askona/askonalogo.webp" alt="">
          <h1 class="col-7 display-2 fw-bolder">askona</h1>
        </div>
        <div class="tcenter d-lg-none mt-4 mb-5">
          <img src="../assets/img/askona/askona1.webp" class="img-fluid col-10">
        </div>
        <div class="border-start border-green border-5 py-2 ps-4 ps-lg-5 pe-lg-4">
          <h4>
            Данное приложение предназначено для использования менеджерами продажам в торговом зале, позволяет быстро регистрировать интерес к определенному товару и оформлению Лида. Является частью системы CRM которая была реализована, нашими специалистами, ранее на базе 1С Управление торговлей для Казахстана.
          </h4>
        </div>
      </div>
      <div class="col-5 fullHeight d-none d-lg-flex">
        <img src="../assets/img/askona/askona1.webp" class="img-fluid">
      </div>

      <div class="my-5 py-lg-4"></div>

      <div class="col-lg-6 ps-lg-5 mb-4 mb-lg-0"><h5>Платформ:</h5><h5 class="fw-bold">Веб, настольные и мобильные устройства</h5></div>
      <div class="col-lg-6"><h5>Конечные результаты:</h5><h5 class="fw-bold">UI, UX, бренд и стратегия</h5></div>
      
      <div class="my-lg-4 py-4"></div>
    </div>

    <div class="row position-relative justify-content-center px-0">
      <img class="position-absolute img-fluid col-6 end-50 me-5 px-5 d-none d-lg-flex" src="../assets/img/askona/img1.webp" alt="">
      <div class="col-10 col-xl-9 row p-0">
        <div class="col d-none d-lg-flex">
        </div>
        <div class="col-lg-6">
          <div class="my-xl-4 py-xl-5"></div>
          <div class="pt-5"></div>
          <div>
            <div class="border-end border-green border-5 text-end my-lg-5 py-2 py-lg-5 pe-4 pe-lg-5">
              <h4 class="pb-">
                К нам обратилась компания Askona.kz, чтобы мы разработали им чат-бот для telegram, который позволял бы регистрировать интерес к товару и контакты потенциального/постоянного Клиента.
              </h4>
            </div>
          </div>
          <div class="my-lg-5 py-lg-5 text-center">
            <img class="img-fluid col-10 col-lg-9 d-lg-none girl" src="../assets/img/askona/img1.webp" alt="">
          </div>
          <div class="my-xl-5 py-xl-5"></div>
        </div>
      </div>
    </div>

    <div class="col-10 p-4 p-lg-5" id="tasks">
      <div  class="p-lg-4">
        <div class="border-start border-green border-5 text-uppercase ps-4 ps-lg-5 py-2">
          <h4 class="fw-bold">Задачи</h4>
        </div>
        <div class="p-0">
          <h4 class="mt-lg-5 pt-4 px-0">
            Проанализировав масштаб работ, нашими специалистами было решено, предложить клиенту более подходящую реализацию для их потребности, а точнее WebApps приложение для Telegram. WebApps нужно было разработать в стиле Single Page Application (SPA) на фреймворке Vue JS, и все это выполнить в рамках Progressive Web Application (PWA).
          </h4>
        </div>
      </div>
    </div>

    <div class="col-10 col-xl-9 mt-lg-5">
      <div class="border-end border-green border-5 text-end mt-5 pe-4 px-lg-5 py-2">
        <h5>
          Данный подход помог реализовать приложение с удобным UI, в отличие от классического чат-бота, в котором возможно работать только в режиме очереди сообщений. UI позволяет пользователю работать как в обычном мобильном приложении, заполняя поля, нажимая кнопки и используя списки и фильтры для работы с товарными категориями.
        </h5>
      </div>
      <div class="py-lg-5"></div>
      <div class="border-start border-green border-5 text-uppercase my-5 py-2 ps-4 ps-lg-5">
        <h4 class="fw-bolder">Этапы разработки</h4>
      </div>
    </div>

    <img class="img-fluid p-0" src="../assets/img/askona/askona.webp" alt="">
    
    <div class="col-10 col-xl-9">
      <div class="border-start border-green border-5 my-5 ps-4 ps-lg-5 pe-3 py-2">
        <h5 class="col-lg-11 pe-lg-5">
          Среди многих задач основной было создание конвейеров найма, которые направлены на оптимизацию процесса найма для конечных пользователей платформы. Помимо первичных, необходимо было разработать пользовательские конвейеры, которые конечные пользователи будут создавать для промежуточных результатов отбора кандидатов.
          <br /><br /><br />
          Сложность заключается в том, что невозможно предсказать, сколько первичных или пользовательских конвейеров создаст конечный пользователь. Поэтому, как только мы получили запрос, наша команда начала мозговой штурм возможных решений для этой задачи. После тщательного анализа мы решили выделить часть трубопроводов к первичным, а другую часть - к пользовательским. Основные решения для Expedo Recruitment включали:
        </h5>
      </div>
    </div>
  </section>
  
  <div class="bgopacgreen container-fluid d-flex justify-content-center p-0">
    <section class="row justify-content-center">
      <div class="col-10 col-xl-9 my-lg-5 py-5">
        <div class="row align-items-center">
          <div class="col-lg-7 text-light mb-5">
            <h1 class="mb-5 text-uppercase fw-bold">
              1.Разработка Application Programming Interface (API)
            </h1>
            <h4>
              Разработка была начата с реализации, Application Programming Interface для 1С Управления торговлей 3 для Казахстана. Данная технология позволяет обмениваться данными с основной учетной системой, и позволяет работать нашему Решению в режиме реального времени. API был реализован с использованием технологии REST API (HTTP сервис - в терминах 1С Предприятия), формат данных был выбран JSON, данный формат является компактным, и очень гибким при масштабировании моделей данных.
            </h4>
          </div>
          <div class="col-lg-5">
            <img class="img-fluid" src="../assets/img/askona/askona2.webp" alt="">
          </div>
        </div>
      </div>

    </section>
  </div>

  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 my-lg-5 py-5">
      <div class="row">
        <div class="col-5 d-none d-lg-block">
          <img class="img-fluid" src="../assets/img/askona/askona3.webp" alt="">
        </div>
        <div class="col-lg-7 text-end">
          <h1 class="mb-5 ms-5 ps-lg-5 text-uppercase fw-bold">
            2.разработка UI
          </h1>
          <h4>
            Следующим этапом была разработка UI будущего решения. В UI входила полная разработка компонентной модели основанной на перечне функций, которые требовались клиенту, т.к. функции были представлены в виде бизнес требований.Поэтому каждая из функций в процессе реализации UI, разрабатывалась в production варианте  для тестирования в реальном полевом режиме.
          </h4>
        </div>
        <div class="d-lg-none text-center mt-4">
          <img class="img-fluid col-10" src="../assets/img/askona/askona3.webp" alt="">
        </div>
      </div>
    </div>
  </section>

  <div class="bgopacgreen container-fluid d-flex justify-content-center p-0">
    <section class="row justify-content-center">
      <div class="col-10 col-xl-9 my-5 py-lg-5">
        <div class="row align-items-center">
          <div class="col-lg-7 text-light">
            <h1 class="text-uppercase fw-bold mb-lg-5 pb-lg-5">
              3.интеграция UI с API
            </h1>
            <h4 class="pt-5">
              Последним этапом была выполнена интеграция UI с API, что позволяло обмениваться данными и уже оформлять сделки в боевом режиме.
            </h4>
          </div>
          <div class="col-lg-5 mt-5 mt-lg-0">
            <img class="img-fluid" src="../assets/img/askona/askona2.webp" alt="">
          </div>
        </div>
      </div>

    </section>
  </div>

  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row mt-lg-5 pt-5 p-0">
      <div class="text-uppercase pb-lg-5">
        <h1 class="fw-bold text-green">Результаты</h1>
        <h1 class="fw-bold">Огромное влияние</h1>
      </div>
      <div>
        <div class="border-start border-green border-5 my-5 ps-4 ps-lg-5 py-3">
          <h5 class="col-xl-7">
            Мы помогли LoveTheSales увеличить количество регистраций и конверсий в среднем на 30%. Бренд теперь имеет последовательный имидж, которому люди могут доверять и к которому они могут возвращаться, когда думают о «продажах моды». Мы устранили препятствия для удобства использования по всем направлениям и, к большому удовольствию инженерной команды, предоставили перспективную систему проектирования, созданную для роста.
          </h5>
        </div>
      </div>
      <div>
        <div class="d-flex mb-5 pb-lg-5">
          <div class="col-8 col-lg-3 ps-0 pe-lg-5 text-center">
            <img class="img-fluid col-8" src="../assets/img/icon/icAsk1.webp" alt="">
            <h5>Увеличение числа регистраций и коэффициента конверсии</h5>
          </div>
          <div class="col-8 col-lg-3 px-lg-4 text-center">
            <img class="img-fluid col-8" src="../assets/img/icon/icAsk2.webp" alt="">
            <h5>Исправлены проблемы с согласованностью и удобством использования</h5>
          </div>
          <div class="col-8 col-lg-3 px-lg-4 text-center">
            <img class="img-fluid col-8" src="../assets/img/icon/icAsk3.webp" alt="">
            <h5>Увеличение конверсии добавления в сумку</h5>
          </div>
          <div class="col-8 col-lg-3 ps-lg-5 pe-0 text-center">
            <img class="img-fluid col-8 p-4" src="../assets/img/askona/askonalogo.webp" alt="">
            <h5>Покупателям 7M понравился новый бренд и веб-сайт</h5>
          </div>
        </div>
      </div>
      <div class="text-center pt-4 py-lg-5">
        <h1 class="display-5 text55 text-green">У вас есть проект для нас?</h1>
        <h1 class="display-3 text33 text-uppercase fw-bold mb-5">Давайте поболтаем!</h1>
        <div class="d-flex justify-content-center">
          <h4 class="text4 col-xl-6">Получите поддержку с помощью большой 
            платформы, конкретизируйте свой первый MVP,
            перейдите от концепции к инвестициям или 
            выведите свой стартап на новый уровень. Мы
            здесь, чтобы поддержать ваши уникальные 
            бизнес-потребности.
          </h4>
        </div>
        <a href="#geoform">
          <div class="btn btn-green rounded-pill mt-5 py-2 px-5">
            <h5>оставить заявку</h5>
          </div>
        </a>
      </div>
    </div>
  </section>

  <PartnersVue />
  
  <GeoForm />
    
  <QuestionVue />

  <FooterVue />

</main>
</div>
</template>
    
<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name:'AboutUs',
  components:{
    HeaderMenu,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  }
}
</script>

<style scoped>
#tasks{
  box-shadow: 1px 0px 18px rgba(0, 0, 0, 0.25);
}
.bgopacgreen{
  background: rgba(0, 123, 127, 0.3);
}
.girl{
  z-index: -999999;
}
</style>
