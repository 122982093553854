import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AboutUs from '../views/AboutUs.vue'
import ProductsPage from '../views/ProductsPage.vue'
import ProjectsPage from '../views/ProjectsPage.vue'
import ServicesPage from '../views/ServicesPage.vue'
import OurWork from '../views/OurWork.vue'
import AskonaPage from '../views/AskonaPage'
import GosuTSDPage from '../views/GosuTSDPage'
import WebSitePage from '../views/WebSitePage'
import MobileDevPage from '../views/MobileDevPage'
import Dev1CPage from '../views/Dev1CPage'
import UxUiPage from '../views/UxUiPage'
import GosuVendorPage from '../views/GosuVendorPage'
import GosuGBAPage from '../views/GosuGBAPage'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/AboutUs',
    name: 'aboutUs',
    component: AboutUs
  },
  {
    path: '/ProductsPage',
    name: 'products',
    component: ProductsPage
  },
  {
    path: '/ProjectsPage',
    name: 'project',
    component: ProjectsPage
  },
  {
    path: '/Services',
    name: 'services',
    component: ServicesPage
  },
  {
    path: '/OurWork',
    name: 'ourWork',
    component: OurWork
  },
  {
    path: '/AskonaPage',
    name: 'askona',
    component: AskonaPage
  },
  {
    path: '/GosuTSDPage',
    name: 'gosuTSD',
    component: GosuTSDPage
  },
  {
    path: '/WebSitePage',
    name: 'website',
    component: WebSitePage
  },
  {
    path: '/MobileDevPage',
    name: 'mobiledev',
    component: MobileDevPage
  },
  {
    path: '/Dev1CPage',
    name: 'dev1c',
    component: Dev1CPage
  },
  {
    path: '/UxUiPage',
    name: 'uxui',
    component: UxUiPage
  },
  {
    path: '/GosuVendorPage',
    name: 'gosuVendor',
    component: GosuVendorPage
  },
  {
    path: '/GosuGBAPage',
    name: 'gosuGBA',
    component: GosuGBAPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return savedPosition || new Promise ((resolve)=>{
      setTimeout(()=> resolve({ top: 0, behavior: 'auto' }), 300)
    })
  }
})

export default router

