<template>
<div> 
<main class="d-flex align-items-center flex-column">  
<HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">

      <div class="col-lg-6 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-5 mb-lg-4"></h4>
        <div class="d-flex">
          <h1 class="display-2 fw-bolder me-3">Gosu</h1>
          <h1 class="display-2 fw-bolder text-primary">TSD</h1>
        </div>
        <div class="text-center d-lg-none my-5">
          <img class="col-9 col-sm-7 img-fluid" src="../assets/img/tsd1.webp" alt="">
        </div>
        <div class="my-5 text-center">
          <h4 class="border-start border-primary border-5 text-uppercase text-start ps-4 ps-lg-5 mb-5">
            мобильное приложение терминал сбора данных
          </h4>
          <a href="https://api.whatsapp.com/send?phone=77788181168" target="_blank">
            <button class="btn btn-outline-primary text-uppercase fw-bold px-5 py-3">
              <h6 class="fw-bold m-0 mx-3">приобрести</h6> 
            </button>
          </a>
        </div>
      </div>
      <div class="col-6 text-end fullHeight d-none d-lg-flex">
        <img class="img-fluid" src="../assets/img/tsd1.webp" alt="">
      </div>

    </div>
    <div class="col-10 mt-5 p-3 p-lg-5 shad">
      <div class="mx-sm-4 mt-4 mt-lg-5 text-center ">
        <h3 class="fw-bolder d-inline-block text3">
          <p class="m-0 text-primary fw-bolder d-inline-block">
            GOSU TSD  -  
          </p>
          ЭТО КРОСПЛАТФОРМЕННАЯ РАЗРАБОТКА 
          АДАПТИРОВАНАЯ ПОД ЛЮБУЮ ОПЕРАЦИОННУЮ СИСТЕМУ
        </h3>
      </div>
      <div class="row justify-content-center mb-lg-4">
        <div class="col d-none d-lg-block"></div>
        <div class="col col-md-3 col-lg-2 p-2 p-xl-4 mx-lg-4"><img src="../assets/img/icon/ictsd1.webp" alt="" class="img-fluid p-3"></div>
        <div class="col col-md-3 col-lg-2 p-2 p-xl-4 mx-lg-4"><img src="../assets/img/icon/ictsd2.webp" alt="" class="img-fluid p-3"></div>
        <div class="col col-md-3 col-lg-2 p-2 p-xl-4 mx-lg-4"><img src="../assets/img/icon/ictsd3.webp" alt="" class="img-fluid p-3"></div>
        <div class="col d-none d-lg-block"></div>
      </div>
    </div>
    <div class="my-lg-5 py-4"></div>
    <div class="row position-relative justify-content-center px-0">
      <img class="position-absolute img-fluid col-5 end-50 px-5 d-none d-lg-block" src="../assets/img/tsd2.webp" alt="">
      <div class="col-10 col-xl-9 row p-0">
        <div class="col d-none d-lg-block">
        </div>
        <div class="col-lg-7 d-flex flex-column align-items-end text-end">
          <div class="my-xl-4 py-xl-5"></div>
          <div class="my-3"></div>
          
          <div class="col-xl-9 border-end border-primary border-5 mb-3 py-2 pe-4 pe-lg-5">
            <h4 class="text-uppercase fw-bold d-inline-block me-2">
              Gosu
            </h4>
            <h4 class="text-uppercase fw-bold d-inline-block text-primary me-2">
              TSD
            </h4> 
            <h4 class="text-uppercase fw-bold d-inline-block">
              разработан с
            </h4>
            <h4 class="text-uppercase fw-bold ">
              любовью к деталям
            </h4>
          </div>
          <div class="pe-4 pe-lg-5 ps-0">
            <h5>
              Программа продумана до&nbsp;мелочей: она не&nbsp;нуждается в&nbsp;постоянном подключении к&nbsp;интернету. Все данные и&nbsp;операции хранятся локально. В&nbsp;зависимости от&nbsp;задач, Gosu&nbsp;TSD позволяет сотрудникам работать в&nbsp;нескольких режимах. Все действия сотрудников записываются и&nbsp;доступны руководителям круглосуточно. А&nbsp;интерфейс программы оптимизирован для работы с&nbsp;помощью сенсорных экранов.
            </h5>
          </div>
          <div class="d-flex container position-relative p-0">
            <div class="col d-none d-md-block d-lg-none text-center">
              <img class="img-fluid col-6" src="../assets/img/tsd2.webp" alt="">
            </div>
            <div class="col-11 col-sm-8 col-md-6 col-lg-10 py-4 py-lg-0">
              <div class="d-flex justify-content-end my-4 p-0">
                <h5 class="text-primary fw-bold me-2">Gosu TSD </h5> 
                <h5> распознает:</h5>
              </div>
              <div class="text-uppercase justify-content-end p-0">
                <div class="d-lg-flex ">
                  <h5 class="fw-bold text-primary me-lg-5">Datamatrix код</h5>
                  <h5 class="fw-bold text-primary me-lg-5">QR код</h5>
                  <h5 class="fw-bold text-primary">Штрих код</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="my-xl-5 py-xl-5"></div>
          <div class="my-5"></div>
        </div>
      </div>
    </div>
    <div class="col-10 col-xl-9 shad p-4 p-lg-5">
      <div class="text-center mt-lg-5">
        <h2 class="fw-bolder">ИНТЕГРАЦИЯ С ЛЮБЫМИ УЧЕТНЫМИ СИСТЕМАМИ</h2>
      </div>
      <div class="mb-lg-5 px-xl-5 pt-2 pt-lg-5">
        <div class="row align-items-center">
          <div class="col col-lg-3 px-lg-5"><img class="img-fluid px-2" src="../assets/img/icon/ictsd4.webp" alt=""></div>
          <div class="col col-lg-3 px-lg-5"><img class="img-fluid px-2" src="../assets/img/icon/ictsd5.webp" alt=""></div>
          <div class="col col-lg-3 d-none d-lg-block">     <img class="img-fluid px-2" src="../assets/img/icon/ictsd6.webp" alt=""></div>
          <div class="col col-lg-3 px-lg-5"><img class="img-fluid px-2" src="../assets/img/icon/ictsd7.webp" alt=""></div>
        </div>
      </div>
    </div>
    <div class="my-5 py-4"></div>
    <div class="col-10 col-sm-7 col-lg-4 py-5 shad">
      <div class="row">
        <div class="col-lg-auto p-0">
          <div class="text-center text-lg-start">
            <img src="../assets/img/logoOrigBP.webp" class="col-2 col-lg-3 mb-4 mb-lg-5 ps-lg-5">
          </div>
          <div class="text-center text-lg-start ps-lg-5">
            <h1 class="fw-bold display-5 me-3">Gosu <p class="fw-bold display-5 text-primary d-inline">TSD</p></h1>
          </div>
          <div class="row align-items-center mt-4 px-4 d-lg-none">
            <!-- <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6> -->
          </div>
          <div class="price text-light">
            <img src="../assets/img/elemPrice100kPrimary.webp" class="img-fluid col-11">
            <!-- <h1 class="display-5 fw-bold my-2 my-lg-3">100 000 ₸</h1> -->
          </div>
          <div class="row d-non d-lg-flex">
            <div class="col-3"></div>
            <p class="fw-bold col-8 col-xl-6 ms-3">+бесплатная настройка для типовых конфигураций</p>
          </div>
          <div class="mt-4 ps-lg-5 text-center">
            <a href="https://api.whatsapp.com/send?phone=77788181168" target="_blank">
              <button class="btn btn-outline-primary px-5 py-3">
                <h6 class="fw-bold text-uppercase m-0 mx-2">приобрести</h6> 
              </button>
            </a>
          </div>
        </div>
        <!-- <div class="col-6 row align-items-center pe-5 d-none d-lg-flex">
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
        </div> -->
      </div>
    </div>
    <div class="my-lg-5"></div>

  </section>

  <PartnersVue />
  
  <GeoForm />
    
  <QuestionVue />

  <FooterVue />

</main>
</div>
</template>
    
<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name:'AboutUs',
  components:{
    HeaderMenu,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  }
}
</script>

<style scoped>
#tasks{
  box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.25);
}
.bgopacgreen{
  background: rgba(0, 123, 127, 0.3);
}
</style>
