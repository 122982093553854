<template>
  <div class="col-lg-11">
    <div class="border-start border-primary border-5 py-2 ps-3 ps-lg-5 m-0">
      <h4 class="fw-bolder">НАША КОМАНДА</h4>
    </div>
    <div class="ps-lg-5 mt-4 mb-5 pt-2 pt-lg-0">
      <h5 class="">Gosu — это компания замечательных людей, которые любят то, что они делают, и эта страсть воплощается в превосходных продуктах для наших клиентов.</h5>
    </div>
  </div>
  <div class="container-fluid row d-lg-none">
    <swiper 
      :slides-per-view="1.4"
      :centered-slides="true"
      :space-between="1"
      :modules="[Autoplay]"
      loop
      Parallax
      :autoplay="{
          delay: 0.5,
          disableOnInteraction: false
        }"
      :speed="3000"
      :effect="slide"
      :breakpoints="swipbreakp"
    >
      <swiper-slide>
        <div class="card mx-3" style="height: 98%">
          <div class="card-body">
            <img class="img-fluid" src="/assets/img/team/Fara.webp">
            <div class="card-title mt-3 fw-bold">Фархат Шамуров</div>
            <div class="card-text">CEO</div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="card mx-3" style="height: 98%">
          <div class="card-body">
            <img class="img-fluid" src="/assets/img/team/KimV.webp">
            <div class="card-title mt-3 fw-bold">Вячеслав Ким </div>
            <div class="card-text">Коммерческий директор</div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="card mx-3" style="height: 98%">
          <div class="card-body">
            <img class="img-fluid" src="/assets/img/team/Aleksandr.webp">
            <div class="card-title mt-3 fw-bold">Александр Шацких</div>
            <div class="card-text">Технический директор</div>
          </div>
        </div>
      </swiper-slide>
      <template  v-for="card in cards" :key="card">
        <swiper-slide>
          <div class="card mx-3" style="height: 98%">
            <div class="card-body">
              <img class="img-fluid" :src="card.imgsrc">
              <div class="card-title mt-3 fw-bold">{{ card.title }}</div>
              <div class="card-text">{{ card.subtitle }}</div>
            </div>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
  <div class="container-fluid d-none d-lg-block p-0">
    <div class="row">
      <div class="col-3 my-2">
        <div class="card mx-2 mx-xl-3" style="height: 100%">
          <div class="card-body">
            <img class="img-fluid" src="/assets/img/team/Fara.webp">
            <div class="card-title mt-3 fw-bold">Фархат Шамуров</div>
            <div class="card-text">CEO</div>
          </div>
        </div>
      </div>
      <div class="col-3 my-2">
        <div class="card mx-2 mx-xl-3" style="height: 100%">
          <div class="card-body">
            <img class="img-fluid" src="/assets/img/team/KimV.webp">
            <div class="card-title mt-3 fw-bold">Вячеслав Ким</div>
            <div class="card-text">Коммерческий директор</div>
          </div>
        </div>
      </div>
      <div class="col-3 my-2">
        <div class="card mx-2 mx-xl-3" style="height: 100%">
          <div class="card-body">
            <img class="img-fluid" src="/assets/img/team/Aleksandr.webp">
            <div class="card-title mt-3 fw-bold">Александр Шацких</div>
            <div class="card-text">Технический директор</div>
          </div>
        </div>
      </div>
      <div class="col-3 position-relative">
        <swiper 
        :slides-per-view="1.0"
        loop
        :modules="[Autoplay, EffectFade]"
        :pagination="{
          clickable: true,
        }"
        :autoplay="{
            delay: 1000,
            disableOnInteraction: false
          }"
        :speed="1000"
        :effect="'fade'"
        >
          <template  v-for="card in cards" :key="card">
            <swiper-slide style="height: 393.5px!important;" class="bg-white">
              <div class="card my-2 mx-2 mx-xl-3" style="height: 375.5px!important">
                <div class="card-body">
                  <img class="img-fluid" :src="card.imgsrc">
                  <div class="card-title mt-3 fw-bold">{{ card.title }}</div>
                  <div class="card-text">{{ card.subtitle }}</div>
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Autoplay, EffectFade} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";

export default{
  name: 'home-page',
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
      return {
        Autoplay,
        EffectFade,
        swipbreakp:{
          550:{
            slidesPerView: 4.0,
            centeredSlides: false,
          }
        }
      };
    },
  data() {
    return {
      cards: [
        {
          imgsrc: '/assets/img/team/Boria.webp',
          title: 'Борислав Жоголев',
          subtitle:'Разработчик C#'
        },
        {
          imgsrc: '/assets/img/team/Denis.webp',
          title:'Денис Мищенко',
          subtitle:'Инженер IoT'
        },
        {
          imgsrc: '/assets/img/team/Dilya.webp',
          title:'Дильназ Калиева',
          subtitle:'Web-дизайнер'
        },
        {
          imgsrc: '/assets/img/team/Misha.webp',
          title:'Михаил Масальцов',
          subtitle:'Разработчик C#'
        },
        {
          imgsrc: '/assets/img/team/Nur.webp',
          title:'Нӯр Арманӯлы',
          subtitle:'Разработчик 1C'
        },
        {
          imgsrc: '/assets/img/team/Laura.webp',
          title:'Лаура Туменбаева',
          subtitle:'Руководитель отдела сопровождения 1С'
        },
        {
          imgsrc: '/assets/img/team/Aru.webp',
          title:'Аружан Куаныш',
          subtitle:'Web-разработчик'
        },
        {
          imgsrc: '/assets/img/team/Uri.webp',
          title:'Юрий Тян',
          subtitle:'Руководитель отдела разработки 1С'
        },
        {
          imgsrc: '/assets/img/team/Vlad.webp',
          title:'Владислав Столяров',
          subtitle:'Разработчик 1C'
        },
        {
          imgsrc: '/assets/img/team/Aiau.webp',
          title:'Аяулым Иманбаева',
          subtitle:'Специалист СММ'
        },
        {
          imgsrc: '/assets/img/team/Vvv.webp',
          title:'Иван Деришев',
          subtitle:'Разработчик C#'
        },
        {
          imgsrc: '/assets/img/team/Sergei.webp',
          title:'Сергей Ли',
          subtitle:'Преподаватель'
        },
        {
          imgsrc: '/assets/img/team/Said.webp',
          title:'Саид Турдиев',
          subtitle:'Руководитель отдела продаж'
        },
        {
          imgsrc: '/assets/img/team/Damir.webp',
          title:'Дамир Саидов',
          subtitle:'Разработчик AI                              '
        },
      ]
    }
  }
}
</script>

<style scoped>
.card{
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
</style>
