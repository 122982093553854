<template>
  <div
    class="modal fade"
    id="exampleModal1"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="modal-header pt-lg-3 pe-lg-4">
          <h4 class="pe-lg-3 text-primary fw-bold">
            Заполните короткую заявку и мы свяжемся с вами
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalForm"
          ></button>
        </div>
        <div class="card-text mt-4 зе-2">
          <form class="row justify-content-between" id="tlgrm">
            <div class="col-5">
              <input
                class="form-control rounded-3"
                type="text"
                name="name"
                placeholder="Ваше имя"
                required
              />
            </div>
            <div class="col-6">
              <input
                class="form-control rounded-3"
                type="email"
                name="email"
                placeholder="Email"
                required
              />
            </div>
            <div class="mt-4 mt-lg-3">
              <input
                class="form-control rounded-3"
                type="text"
                placeholder="Ваш номер телефона"
                id="number"
                name="number"
                required
              />
            </div>
            <div class="mt-4 mt-lg-3">
              <input
                class="form-control rounded-3"
                type="text"
                placeholder="Кратко опишите ваш проект"
                name="theme"
                required
              />
            </div>
            <div class="mt-4 mt-lg-3">
              <input
                type="text"
                class="form-control rounded-3"
                name="details"
                placeholder="Детали проекта (необязательно)"
              />
            </div>
            <button
              class="btn btn-outline-primary text-uppercase py-3 mb-3 mt-4"
              type="submit"
              @click="submited"
            >
              <h6 class="fw-bold m-0 mx-lg-3">отправить заявку</h6>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import axios from "axios";

export default {
  name: "FormS",
  methods: {
    async submited() {
      const TOKEN = "5810296150:AAEoyW_Uibhd6TgCA10GrmmXOHbh1e5VSC0";
      const CHAT_ID = "163616319";
      const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
      const success = document.getElementById("success");
      const danger = document.getElementById("danger");

      document.getElementById("tlgrm").addEventListener("submit", function (e) {
        e.preventDefault();
        let message = ` `;
        message += `<b>Отправитель: </b> ${this.name.value}\n`;
        message += `<b>Почта: </b> ${this.email.value}\n`;
        message += `<b>Номер: </b> ${this.number.value}\n`;
        message += `<b>ПРОЕКТ: </b> ${this.theme.value}\n`;
        message += `<b>Детали: </b> ${this.details.value}\n`;
        console.log(message);

        axios
          .post(URI_API, {
            chat_id: CHAT_ID,
            parse_mode: "html",
            text: message,
          }) // eslint-disable-next-line
          .then((res) => {
            this.name.value = "";
            this.email.value = "";
            this.number.value = "";
            this.theme.value = "";
            this.details.value = "";
            success.innerHTML = "Заявка отправлена успешно!";
            success.style.display = "block";
            setTimeout(function () {
              document.getElementById("success").style.display = "none";
            }, 3000);
            document.getElementById("closeModalForm").click();
          })
          .catch((err) => {
            console.warn(err);
            danger.innerHTML = "Ошибка отправки!";
            danger.style.display = "block";
            setTimeout(function () {
              document.getElementById("danger").style.display = "none";
            }, 3000);
          })
          .finally(() => {
            console.log("END");
          });
      });
    },
  },
};
</script>
