<template>
<div> 
<main class="d-flex align-items-center flex-column">
<HeaderMenu />
<section class="row justify-content-center">
  <div class="col-10 col-xl-9 row p-0 position-relative">

    <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
      <h4 class="mb-5 mb-lg-4"></h4>
      <div>
        <h1 class="display-3 fw-bold">Наши </h1>
        <h1 class="display-3 fw-bold text-primary">ПРОЕКТЫ</h1>
      </div>
      <div class="d-lg-none text-center my-5" style="overflow: hidden;">
        <img src="../assets/img/projectimg1.webp" class="img-fluid col-9 my-4">
      </div>
      <div class="my-5">
        <div class="border-start border-primary border-5 ps-4 ps-lg-5">
          <h4 class="pe-3">Вот некоторые из представленных проектов, над которыми мы работали. 
            <a href="#geoform" class="text-decoration-none text-primary">Свяжитесь с нами</a>
            , чтобы получить более подробную информацию о других наших работах и предыдущих клиентах.
          </h4>
        </div>
      </div>
    </div>
    <div class="col-5 fullHeight d-none d-lg-flex">
      <img src="../assets/img/projectimg1.webp" class="img-fluid position-absolute start-50 col-8">
    </div>
    <div class="py-4"></div>
    <ProjectsVue />
  </div>
</section>

<PartnersVue />
<GeoForm />
<QuestionVue />
<FooterVue />
</main>
</div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue'

// import ProductsVue from '@/components/ProductsVue.vue'
import ProjectsVue from '@/components/ProjectsVue'
// import GosuTeam from '@/components/GosuTeam.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
    name: 'product-page',
    components:{
      HeaderMenu,
      // ProductsVue,
      ProjectsVue,
      // GosuTeam,
      PartnersVue,
      GeoForm,
      QuestionVue,
      FooterVue
    },
    data(){
      return{
        title:{
          url: 'Дом - Проекты58954',
          title1: 'Наши',
          title2: '45554465645',
          subtitle1: 'Вот некоторые из представленных проектов, над которыми мы работали.',
          subtitle2: 'Свяжитесь с нами',
          subtitle3: ', чтобы получить более подробную информацию о других наших работах и предыдущих клиентах.'
      }
      }
    }
}
</script>
