<template>
  <div
    class="container-fluid position-relative d-flex justify-content-center p-0 mb-5 mb-lg-5"
    id="geoform"
  >
    <iframe
      class="position-absolute d-none d-lg-block"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3Acb75f45dadf78dc8c933a5db83611b70e5515bbbbd23f7cac2e12632d36b6419&amp;source=constructor"
      width="100%"
      height="600"
      frameborder="0"
    ></iframe>
    <section class="row justify-content-center my-lg-5">
      <div class="col-10 col-sm-8 col-lg-10 col-xl-9">
        <div class="col-lg-6 col-xxl-5 card p-3">
          <div class="card-body">
            <div class="card-title">
              <h4 class="text-primary fw-bold">
                Заполните короткую заявку и&nbsp;мы&nbsp;свяжемся с&nbsp;вами
              </h4>
            </div>
            <div class="card-text mt-5">
              <form class="row justify-content-between" id="telegram">
                <div class="col-5">
                  <input
                    placeholder="Ваше имя"
                    class="form-control rounded-3"
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <div class="col-6">
                  <input
                    placeholder="Email"
                    class="form-control rounded-3"
                    type="email"
                    name="email"
                    required
                  />
                </div>
                <div class="mt-4">
                  <input
                    placeholder="Ваш номер телефона"
                    class="form-control rounded-3"
                    type="number"
                    name="number"
                    required
                    id="number"
                  />
                </div>
                <div class="mt-4">
                  <input
                    placeholder="Кратко опишите ваш проект"
                    class="form-control rounded-3"
                    type="text"
                    name="theme"
                    required
                  />
                </div>
                <div class="mt-4">
                  <input
                    placeholder="Детали проекта (необязательно)"
                    class="form-control rounded-3"
                    type="text"
                    name="details"
                  />
                </div>
                <button
                  class="btn btn-outline-primary text-uppercase mt-5 mb-3 py-3"
                  type="submit"
                  @click="subm"
                >
                  <h6 class="fw-bold m-0 mx-lg-3">отправить заявку</h6>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="fixed-top">
      <div
        id="success"
        class="alert alert-success float-end col-10 col-sm-6 col-md-4 col-lg-3 mt-5 m-md-5 text-center"
        role="alert"
        style="display: none"
      ></div>
      <div
        id="danger"
        class="alert alert-danger float-end col-10 col-sm-6 col-md-4 col-lg-3 mt-5 m-md-5 text-center"
        role="alert"
        style="display: none"
      ></div>
    </div>
  </div>
</template>
<style scoped>
.form-control {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
</style>

<script>
import axios from "axios";

export default {
  methods: {
    async subm() {
      const TOKEN = "5810296150:AAEoyW_Uibhd6TgCA10GrmmXOHbh1e5VSC0";
      const CHAT_ID = "163616319";
      const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
      const success = document.getElementById("success");
      const danger = document.getElementById("danger");

      document
        .getElementById("telegram")
        .addEventListener("submit", function (e) {
          e.preventDefault();
          let message = ` `;
          message += `<b>Отправитель: </b> ${this.name.value}\n`;
          message += `<b>Почта: </b> ${this.email.value}\n`;
          message += `<b>Номер: </b> ${this.number.value}\n`;
          message += `<b>ПРОЕКТ: </b> ${this.theme.value}\n`;
          message += `<b>Детали: </b> ${this.details.value}\n`;
          console.log(message);

          axios
            .post(URI_API, {
              chat_id: CHAT_ID,
              parse_mode: "html",
              text: message,
            }) // eslint-disable-next-line
            .then((res) => {
              this.name.value = "";
              this.email.value = "";
              this.number.value = "";
              this.theme.value = "";
              this.details.value = "";
              success.innerHTML = "Заявка отправлена успешно!";
              success.style.display = "block";
              setTimeout(function () {
                document.getElementById("success").style.display = "none";
              }, 3000);
            })
            .catch((err) => {
              console.warn(err);
              danger.innerHTML = "Ошибка отправки!";
              danger.style.display = "block";
              setTimeout(function () {
                document.getElementById("danger").style.display = "none";
              }, 3000);
            })
            .finally(() => {
              console.log("END");
            });
        });
    },
  },
};
</script>
