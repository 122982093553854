<template>
<div class="">
<main class="d-flex align-items-center flex-column">
<HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">

      <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-5 mb-lg-4"></h4>
        <div class="mt-5 mt-lg-0">
          <h1 class="display-3 fw-bolder">Web - разработка</h1>
        </div>
        <div class="my-5">
          <h4 class="">
            Порадуйте своих клиентов эстетическим внешним видом вашего веб-решения. Мы создаем веб-сайты и платформы с отличной производительностью и красивым дизайном, чтобы наилучшим образом представить ценность вашего бизнеса.
          </h4>
        </div>
      </div>
      <div class="col-5 fullHeight d-none d-lg-flex">
        <img class="img-fluid" src="../assets/img/wsimg1.webp" alt="">
      </div>
      <div class="my-lg-5 py-lg-4"></div>
      <div class="border-start border-5 border-primary ps-4 ps-lg-5 py-2">
        <h4 class="text-uppercase fw-bolder">
          Порядок работы
        </h4>
      </div>
      <div class="text-center py-5 mb-lg-5">
        <img class="img-fluid col-lg-7" src="../assets/img/wsimg2.webp" alt="">
      </div>

      <div class="col-lg-6 d-grid mb-lg-5 wsbgimg">
        <div class="py-5 py-lg-0">
          <h3>Подписание договора</h3>
          <h3>Составление ТЗ</h3>
          <h3>Разработка и утверждение дизайна</h3>
          <h3>Верстка и презентация сайта</h3>
          <h3>Внесение корректировок</h3>
          <h3>Покупка домена и хостинга</h3>
          <h3>Выгрузка сайта</h3>
        </div>
      </div>
      <div class="col-6 d-none d-lg-block"> 
        <img 
          class="img-fluid" 
          src="../assets/img/wsimg3.webp" 
          alt=""
        >
      </div>
      <div class="my-lg-5"></div>
      <div class="border-end border-primary border-5 text-end pe-4 pe-lg-5 my-5 py-2">
        <h4 class="text-uppercase fw-bolder">
          Стоимость
        </h4>
      </div>
      <div class="d-none d-xl-flex">
        <div class="card col-9 col-lg me-4">
          <div class="card-header text-center bg-primary position-relative">
            <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
            <div class="d-flex justify-content-center">
              <h5 class="text-uppercase col-6 fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Сайт-визитка</h5> 
            </div>
          </div>
          <div class="card-body position-relative p-0">
            <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
            <div class="card-title py-4 text-center px-0 price">
              <h4 class="fw-bolder m-0 py-2 ps-5 text-light">от 50 000 ₸</h4>
            </div>
            <div class="card-subtitle text-dark row p-3">
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Уникальный дизайн сайта (не шаблон)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">До 5 страниц сайта</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Кнопки звонка, whatsapp</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
            </div>
          </div>
        </div>
        <div class="card col-9 col-lg me-4">
          <div class="card-header text-center bg-primary position-relative">
            <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
            <div class="d-flex justify-content-center">
              <h5 class="text-uppercase fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Корпоративный <br/>сайт</h5> 
            </div>
          </div>
          <div class="card-body position-relative p-0">
            <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
            <div class="card-title py-4 text-center px-0 price">
              <h4 class="fw-bolder m-0 py-2 ps-5 text-light">от 250 000 ₸</h4>
            </div>
            <div class="card-subtitle text-dark row p-3">
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Уникальный дизайн сайта (не шаблон)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">7-15 страниц сайта</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Кнопки звонка, whatsapp</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка счетчиков статистики посещаемосьти</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Домен (.kz) и хостинг на 1 год</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Создание корпоративной почты</h6>
            </div>
          </div>
        </div>
        <div class="card col-9 col-lg me-4">
          <div class="card-header text-center bg-primary position-relative">
            <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
            <div class="d-flex justify-content-center">
              <h5 class="text-uppercase col-6 fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Интернет-магазин</h5> 
            </div>
          </div>
          <div class="card-body position-relative p-0">
            <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
            <div class="card-title py-4 text-center px-0 price">
              <h4 class="fw-bolder m-0 py-2 ps-5 text-light">от 500 000 ₸</h4>
            </div>
            <div class="card-subtitle text-dark row p-3">
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Уникальный дизайн сайта (не шаблон)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">От 5 страниц сайта</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Заполнение товаров (30 шт.)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Кнопки звонка, whatsapp</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Корзина товаров</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Онлайн-оплата заказов                                                             </h6>
            </div>
          </div>
        </div>
        <div class="card col-9 col-lg">
          <div class="card-header text-center bg-primary position-relative">
            <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
            <div class="d-flex justify-content-center">
              <h5 class="text-uppercase col-6 fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Landing page</h5> 
            </div>
          </div>
          <div class="card-body position-relative p-0">
            <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
            <div class="card-title py-4 text-center px-0 price">
              <h4 class="fw-bolder m-0 py-2 ps-5 text-light">от 100 000 ₸</h4>
            </div>
            <div class="card-subtitle text-dark row p-3">
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Готовый дизайн на выбор</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">До 5 страниц сайта</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка счетчиков статистики посещаемосьти</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Базовая SEO оптимизация (внутренняя)</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Создание корпоративной почты</h6>
              <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex d-xl-none">
        <swiper 
          :slides-per-view="1.2"
          :modules="[Autoplay]"
          loop
          Parallax
          :space-between="20"
          :autoplay="{
              delay: 5000,
              disableOnInteraction: false
            }"
          :speed="3000"
          :effect="slide"
          :breakpoints="swipbreakp"
        >
          <swiper-slide>
            <div class="card swipcard">
              <div class="card-header text-center bg-primary position-relative">
                <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
                <div class="d-flex justify-content-center">
                  <h5 class="text-uppercase col-6 fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Сайт-визитка</h5> 
                </div>
              </div>
              <div class="card-body position-relative p-0">
                <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
                <div class="card-title py-4 text-center px-0 price">
                  <h4 class="fw-bolder m-0 py-2 ps-4 text-light">от 50 000 ₸</h4>
                </div>
                <div class="card-subtitle text-dark row p-3">
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Уникальный дизайн сайта (не шаблон)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">До 5 страниц сайта</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Кнопки звонка, whatsapp</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="card swipcard">
              <div class="card-header text-center bg-primary position-relative">
                <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
                <div class="d-flex justify-content-center">
                  <h5 class="text-uppercase fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Корпоративный <br/>сайт</h5> 
                </div>
              </div>
              <div class="card-body position-relative p-0">
                <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
                <div class="card-title py-4 text-center px-0 price">
                  <h4 class="fw-bolder m-0 py-2 ps-4 text-light">от 250 000 ₸</h4>
                </div>
                <div class="card-subtitle text-dark row p-3">
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Уникальный дизайн сайта (не шаблон)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">7-15 страниц сайта</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Кнопки звонка, whatsapp</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка счетчиков статистики посещаемосьти</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Домен (.kz) и хостинг на 1 год</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Создание корпоративной почты</h6>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="card swipcard">
              <div class="card-header text-center bg-primary position-relative">
                <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
                <div class="d-flex justify-content-center">
                  <h5 class="text-uppercase col-6 fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Интернет-магазин</h5> 
                </div>
              </div>
              <div class="card-body position-relative p-0">
                <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
                <div class="card-title py-4 text-center px-0 price">
                  <h4 class="fw-bolder m-0 py-2 ps-4 text-light">от 500 000 ₸</h4>
                </div>
                <div class="card-subtitle text-dark row p-3">
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Уникальный дизайн сайта (не шаблон)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">От 5 страниц сайта</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Заполнение товаров (30 шт.)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Кнопки звонка, whatsapp</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Корзина товаров</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Онлайн-оплата заказов                                                             </h6>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="card swipcard">
              <div class="card-header text-center bg-primary position-relative">
                <img class="col-5 col-lg-7 position-absolute start-0" src="../assets/img/logogreen.webp" alt="">
                <div class="d-flex justify-content-center">
                  <h5 class="text-uppercase col-6 fw-bold mt-3 pt-lg-3 pb-lg-2 text-light" style="z-index: 3">Landing page</h5> 
                </div>
              </div>
              <div class="card-body position-relative p-0">
                <!-- <img src="../assets/img/Group353.webp" class="img-fluid col-9 position-absolute start-0" style="z-index: 0;" alt=""> -->
                <div class="card-title py-4 text-center px-0 price">
                  <h4 class="fw-bolder m-0 py-2 ps-4 text-light">от 100 000 ₸</h4>
                </div>
                <div class="card-subtitle text-dark row p-3">
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Готовый дизайн на выбор</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Мобильная версия</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Форма обратной связи</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Слайд-шоу</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Первичное заполнение информации</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">До 5 страниц сайта</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Карта проезда</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка счетчиков статистики посещаемосьти</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Регистрация в поисковых системах Google и Яндекс</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Установка SSL сертификата для защиты сайта HTTPS</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Базовая SEO оптимизация (внутренняя)</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Создание корпоративной почты</h6>
                  <i class="bi bi-check2 col-1 p-0"></i><h6 class="col-11 ps-2">Техническая поддержка/помощь клиенту (1 неделя)</h6>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="my-5"></div>
      
      <div class="col-lg-6 mb-5 mb-lg-0">
        <img class="img-fluid" src="../assets/img/wsimg4.webp" alt="">
      </div>
      <div class="col-lg-6 text-end d-grid align-content-center">
        <div class="border-end border-primary border-5 mb-5 py-2 pe-4 pe-lg-5">
          <h4 class="text-uppercase fw-bolder">Зачем вам сайт?</h4> 
        </div>
        <h6 class="ps-lg-5 ms-lg-5">
          Современный бизнес стремительно переходит в сферу онлайн плоскости. Вся коммерческая деятельность осуществляется преимущественно в интернете. До 60% пользователей предпочитают делать покупки виртуально. Тенденции таковы, что электронная коммерция будет только расти и получать популярность. Создать сайт сегодня заинтересован каждый предприниматель и бизнесмен.
        </h6>
      </div>
    </div>
  </section>
  <PartnersVue />

  <GeoForm />

  <QuestionVue />

  <!-- <footer class="mt-5 pt-5"> -->
  <FooterVue />
</main>
</div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
// import Services from '@/components/ServicesVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';

export default{
  name: 'product-page',
  components:{
    HeaderMenu,
    // Services,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue,
    Swiper,
    SwiperSlide,
  },  
  setup() {
    return {
      Autoplay,
      swipbreakp:{
        577:{
          slidesPerView: 3,
        },
        768:{
          slidesPerView: 4,
        },
        992:{
          slidesPerView: 3,
          centeredSlides: true,
        },
        1200:{
          slidesPerView: 6.5,
        },
      }
    };
  },
}
</script>
<style scoped>
.price{
  background-image: url(../assets/img/elemPricePrimary.webp);
  background-size: contain;
  background-repeat: no-repeat;
}
@media(max-width:500px){
  .wsbgimg{
    background-image: url('../assets/img/wsimg3.webp');
    background-size: cover;
  }
}
</style>
