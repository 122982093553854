<template>
<div class="" style="z-index:-3"> 
<main class="d-flex align-items-center flex-column">
<HeaderMenuBlack />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">

      <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-5 pb-5 pb-lg-0 mb-lg-4"></h4>
        <div>
          <h1 class="display-3 fw-bolder">Наши услуги</h1>
        </div>
        <div class="my-5">
          <h4 class="fw-normal">
            Мы полностью сосредоточены на решении ваших бизнес-задач. Независимо от технологии или архитектуры, мы найдем наиболее оптимальное решение и предоставим готовый к эксплуатации продукт.
          </h4>
        </div>
      </div>
      <div class="col-5 text-end fullHeight d-none d-lg-flex">
        <img src="../assets/img/brill.webp" class="img-fluid">
      </div>
      <div class="my-lg-5"></div>
      <ServicesBlack />
      <div class="my-lg-5 py-5"></div>


      <div class="col-lg-7 position-relative">
        <!-- <img class="img-fluid col-lg-10" src="../assets/img/wsimg5.webp" alt=""> -->
      </div>
      <div class="col-lg-5 text-end d-grid align-content-center">
        <div class="border-end border-primary border-5 my-5 mt-lg-0 mb-lg-4 py-2 pe-4 pe-lg-5">
          <h4 class="text-uppercase fw-bolder">Наш фокус</h4> 
        </div>
        <h5>Мы превращаем ваши бизнес-задачи в цифровые 
          продукты «под ключ», будь то пользовательское программное обеспечение, веб-приложение, 
          мобильное приложение или дизайн цифрового решения. Независимо от поставленной задачи, 
          у нас есть команда самых ярких умов, чтобы решить любую бизнес-задачу и создать всемирно 
          известный продукт.
        </h5>
      </div>


    </div>
  </section>


  <div class="py-lg-5"></div>
  <PartnersVue />

  <GeoFormBlack />

  <QuestionVue />

  <!-- <footer class="mt-5 pt-5"> -->
  <FooterVueBlack />
</main>
</div>
</template>

<script>
import HeaderMenuBlack from '@/components/HeaderMenu.vue'
import ServicesBlack from '@/components/ServicesVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoFormBlack from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVueBlack from '@/components/FooterVue.vue'

export default{
  name: 'product-page',
  components:{
    HeaderMenuBlack,
    ServicesBlack,
    PartnersVue,
    GeoFormBlack,
    QuestionVue,
    FooterVueBlack
  },
}
</script>
