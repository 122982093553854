<template>
<div> 
<main class="d-flex align-items-center flex-column">
<HeaderMenu />
<section class="row justify-content-center">
  <div class="col-10 col-xl-9 row p-0">

    <div class="col-lg-6 d-grid align-content-between mt-5 pt-lg-5">
      <h4 class="mb-5 mb-lg-4"></h4>
      <div>
        <h1 class="display-3 fw-bold">Наши </h1>
        <h1 class="display-3 fw-bold text-primary">ПРОДУКТЫ</h1>
      </div>
      <div class="text-center d-lg-none my-5">
        <img src="../assets/img/productimg1.webp" class="img-fluid col-sm-8">
      </div>
      <div class="border-start border-primary border-5 ps-4 ps-lg-5 my-lg-5">
        <h4 class="pe-lg-3">Вот некоторые из представленных проектов, над которыми мы работали. 
          <a href="#geoform" class="text-decoration-none text-primary">Свяжитесь с нами</a>
          , чтобы получить более подробную информацию о других наших работах и предыдущих клиентах.
        </h4>
      </div>
    </div> 
    <div class="col-6 fullHeight d-none d-lg-flex">
      <img src="../assets/img/productimg1.webp" class="img-fluid">
    </div>
    <div class="py-4"></div>
    <ProductsVue />
    
  </div>
</section>

<PartnersVue />
<GeoForm />
<QuestionVue />
<FooterVue />

</main>
</div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import ProductsVue from '@/components/ProductsVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name: 'product-page',
  components:{
    HeaderMenu,
    ProductsVue,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  },
}
</script>
