<template>
  <div class="d-flex justify-content-center" id="scene">
    <div class="col-lg-11 px-lg-2">
      <div class="d-none d-lg-block">
        <div class="card p-2 p-lg-5 mb-5 my-5">
          <div class="row my-lg-2 mx-3">  
            <div class="col-lg-7 card-body px-0 d-grid align-content-between">
              <div class="card-title mb-lg-4">
                <h3 class="text-primary fw-bolder text-uppercase">
                  <img class="img-fluid col-2 col-lg-1 pe-3 pe-lg-2" src="../assets/img/logoOrigBPC.webp" alt="">
                  gosu tsd
                </h3>
              </div>
              <div class="d-lg-none text-center">
                <img class="img-fluid pe-4" src="../assets/img/product1.webp" alt="">
                <img class="img-fluid col-10" src="../assets/img/elemLine.webp" alt="">
              </div>
              <div class="card-text mb-lg-4">
                <h5>Gosu TSD - мобильное приложение терминал сбора данных интегрированный c 1C, позволяющее осуществлять процесс инвентаризации.</h5>
                <h5>Кроссплатформенная разработка адаптированная под любую операционную систему</h5>
                <h5>Интеграция с любыми учетными системами</h5>
              </div>
              <div class="card-text pt-2 tcenter">
                <router-link to="/GosuTsdPage">
                  <button class="btn btn-outline-primary px-5 py-3">
                    <h6 class="fw-bold m-0 mx-2">ПОДРОБНЕЕ</h6> 
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-5 text-center p-0 d-none d-lg-flex flex-column justify-content-center align-items-center">
              <img class="img-fluid" src="../assets/img/product1.webp" alt="">
              <img class="img-fluid col-10 mt-3" src="../assets/img/elemLine.webp" alt="">
            </div>  
          </div>
        </div>
        <div class="card p-2 p-lg-5 my-5  d-none d-lg-block">
          <div class="row my-lg-2 mx-3">
            <div class="col-lg-7 card-body d-grid align-content-between px-0">
              <div class="card-title mb-4">
                <h3 class="text-primary fw-bolder text-uppercase">
                  <img class="img-fluid col-2 col-lg-1 pe-3 pe-lg-2" src="../assets/img/logoOrigBPC.webp" alt="">
                  gosu GBA
                </h3>
              </div>
              <div class="card-text mb-4">
                <h5>Gosu GBA - интегратор API с POS системами.</h5>
                <h5>Интеграция агрегаторов доставки еды с вашей POS.</h5>
                <h5>Программа которая позволяет интегрировать заказы с Wolt и Glovo напрямую на вашу учетную систему без подключения оборудования.</h5>  
              </div>
              <div class="card-text pt-2">
                <router-link to="/GosuGBAPage">
                  <button class="btn btn-outline-primary px-5 py-3">
                    <h6 class="fw-bold m-0 mx-2">ПОДРОБНЕЕ</h6> 
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-5 text-center p-0 d-grid align-items-center">
              <img class="img-fluid" src="../assets/img/product2.webp" alt="">
              <!-- <img class="img-fluid col-10 mt-3" src="../assets/img/elemLine.webp" alt=""> -->
            </div>
          </div>
        </div>
        <div class="card p-2 p-lg-5 my-5  d-none d-lg-block">
          <div class="row my-lg-2 mx-3">
            <div class="col-lg-7 card-body d-grid align-content-between px-0">
              <div class="card-title mb-4">
                <h3 class="text-primary fw-bolder text-uppercase">
                  <img class="img-fluid col-2 col-lg-1 pe-3 pe-lg-2" src="../assets/img/logoOrigBPC.webp" alt="">
                  gosu VENDOR
                </h3>
              </div>
              <div class="card-text mb-4">
                <h5>Gosu Vendor - программное обеспечение для ведения базового учета на предприятии
                </h5>  
              </div>
              <div class="card-text pt-2">
                <router-link to="/GosuVendorPage">
                  <button class="btn btn-outline-primary px-5 py-3">
                    <h6 class="fw-bold m-0 mx-2">ПОДРОБНЕЕ</h6> 
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-5 text-center p-0">
              <img class="img-fluid" src="../assets/img/product3.webp" alt="">
              <img class="img-fluid col-10 mt-3" src="../assets/img/elemLine.webp" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-lg-none row py-5">
    <swiper 
      :slides-per-view="1.01"
      :centered-slides="true"
      :space-between="50"
      :modules="[Autoplay]"
      loop
      Parallax
      :autoplay="{
          delay: 5000,
          disableOnInteraction: false
        }"
      :speed="3000"
      :effect="slide"
      :breakpoints="swipbreakp"
    >
    <swiper-slide>
      <div class="card p-2 col hhh">
        <div class="card-body px-0 d-grid align-content-between mx-3" style="">
          <div>
            <div class="card-title">
              <h3 class="text-primary fw-bolder text-uppercase">
                <img class="img-fluid col-2 pe-3" src="../assets/img/logoOrigBPC.webp" alt="">
                gosu tsd
              </h3>
            </div>
            <div class="text-center">
              <img class="img-fluid pe-4" src="../assets/img/product1.webp" alt="">
              <img class="img-fluid col-10" src="../assets/img/elemLine.webp" alt="">
            </div>
            <div class="card-text mb-lg-4">
              <h5>Gosu TSD - мобильное приложение терминал сбора данных интегрированный с 1C, позволяющее осуществлять процесс инвентаризации.</h5>
            </div>
          </div>
          <div class="card-text pt-2 text-center">
            <router-link to="/GosuTsdPage">
              <button class="btn btn-outline-primary px-5 py-3">
                <h6 class="fw-bold m-0 mx-2">ПОДРОБНЕЕ</h6> 
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="card p-2 col hhh">
        <div class="card-body px-0 d-grid align-content-between mx-3" style="">
          <div>
            <div class="card-title">
              <h3 class="text-primary fw-bolder text-uppercase">
                <img class="img-fluid col-2 col-lg-1 pe-3 pe-lg-2" src="../assets/img/logoOrigBPC.webp" alt="">
                gosu GBA
              </h3>
            </div>
            <div class="text-center">
              <img class="img-fluid pe-4" src="../assets/img/product2.webp" alt="">
              <img class="img-fluid col-10" src="../assets/img/elemLine.webp" alt="">
            </div>
            <div class="card-text mb-4">
              <h5>Gosu GBA - интегратор API с POS системами.</h5>
              <h5>Интеграция агрегаторов доставки еды с вашей POS.</h5>
            </div>
          </div>
          <div class="card-text pt-2 text-center">
            <router-link to="/GosuGBAPage">
              <button class="btn btn-outline-primary px-5 py-3">
                <h6 class="fw-bold m-0 mx-2">ПОДРОБНЕЕ</h6> 
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="card p-2 col hhh">
        <div class="card-body px-0 d-grid align-content-between mx-3" style="">
          <div>
            <div class="card-title">
              <h3 class="text-primary fw-bolder text-uppercase">
                <img class="img-fluid col-2 col-lg-1 pe-3 pe-lg-2" src="../assets/img/logoOrigBPC.webp" alt="">
                gosu VENDOR
              </h3>
            </div>
            <div class="text-center">
              <img class="img-fluid pe-4" src="../assets/img/product3.webp" alt="">
              <img class="img-fluid col-10" src="../assets/img/elemLine.webp" alt="">
            </div>
            <div class="card-text mb-4">
              <h5>Gosu Vendor - программное обеспечение для ведения базового учета на предприятии
              </h5>  
            </div>
          </div>
          <div class="card-text pt-2 text-center">
            <router-link to="/GosuVendorPage">
              <button class="btn btn-outline-primary px-5 py-3">
                <h6 class="fw-bold m-0 mx-2">ПОДРОБНЕЕ</h6> 
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';

export default{
  name: 'home-page',
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
      return {
        Autoplay,
        swipbreakp:{
          380:{
            slidesPerView: 1.0,
            centeredSlides: false,
          },
          576:{
            slidesPerView: 1.4,
            centeredSlides: true,
          },
          768:{
            slidesPerView: 1.8,
            centeredSlides: true
          }
        }
      };
    },
  data() {
    return {
      
    }
  }
}
</script>

<style scoped>
.card{
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
</style>
