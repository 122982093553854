<template>
  <main class="d-flex align-items-center flex-column">
  <HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0 position-relative">
  
      <div class="col-lg-7 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-lg-4"></h4>
        <div class="d-flex mt-5 pt-lg-5">
          <h1 class="display-1 fw-bolder me-3">Gosu</h1>
          <h1 class="display-1 fw-bolder text-primary">Vendor</h1>
        </div>
        <div class="tcenter d-lg-none mt-4">
          <img class="img-fluid col-10" src="../assets/img/vendorimg1.webp" alt="">
        </div>
        <div class="my-5">
          <h4 class="border-start border-primary border-5 text-uppercase ps-4 ps-lg-5 mb-5 pe-lg-5">
            мобильное приложение для ведения учета малого бизнеса
          </h4>
          <!-- <a href="https://api.whatsapp.com/send?phone=77788181168" target="_blank">
            <button class="btn btn-outline-primary text-uppercase fw-bold px-5 py-3">
              <h6 class="fw-bold m-0 mx-3">приобрести</h6> 
            </button>
          </a> -->
        </div>
      </div>
      <div class="col-5 text-end fullHeight d-none d-lg-flex">
        <img class="img-fluid position-absolute start-50 col-7" src="../assets/img/vendorimg1.webp" alt="">
      </div>
      <div class="my-lg-5"></div>
  
    </div>
    <div class="col-10">
      <div class="duble pt pt-xxl-">
        <h2 class="text-center fw-bold pt-4 pt-xl-5 pt-xxl-5">Для кого?</h2>
        <div class="">
          <div class="row justify-content-evenly px-3">
            <div class="col-lg-3">
              <div class="text-center">
                <img src="../assets/img/icon/icvendor6.webp" class="img-fluid col">
                <h4 class="fw-bold">Розничная торговля</h4>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="text-center">
                <img src="../assets/img/icon/icvendor7.webp" class="img-fluid col">
                <h4 class="fw-bold">Оптовая торговля</h4>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="text-center">
                <img src="../assets/img/icon/icvendor8.webp" class="img-fluid col">
                <h4 class="fw-bold">Онлайн-торговля и услуги</h4>
              </div>
            </div>
          <div class="my-5"></div>
        
        </div>
        </div>
      </div>
    </div>
    <div class="col-10 col-xl-9 row justify-content-between">
      <div class="my-lg-5 mt-5"></div>
      <div class="col-5 d-none d-lg-block">
        <img class="img-fluid" src="../assets/img/vendorimg2.webp" alt="">
      </div>
      <div class="col-lg-7 d-flex flex-column align-items-end justify-content-center text-end">
        <div class="">
          <div class="d-flex justify-content-end border-end border-primary border-5 py-2 pe-4 pe-lg-5">
            <h3 class="text-uppercase fw-bold col-lg-10">
              Все <p class="fw-bold d-inline-block text-primary m-0">возможности</p> для управления торговлей
            </h3>
          </div>
          <div class="mt-5">
            <div class="row position-relative mb-4">
              <div class="col-11 pe-3">
                <h4 class="fw-bold">Автоматизация торговли</h4>
                <h5>Быстрый старт — порог входа низкий   Контроль за&nbsp;товарами, выручкой и&nbsp;кассирами в&nbsp;режиме онлайн со&nbsp;смартфона.</h5>
              </div>
              <div class="col">
                <img src="../assets/img/icon/icvendor9.webp" class="position-absolute col-3 col-md-2 px-3 start-100 translate-middle top-50" alt="">
              </div>
            </div>
            <div class="row position-relative mb-4">
              <div class="col-11 pe-3">
                <h4 class="fw-bold">Товароучетная система</h4>
                <h5>Управление товарами и&nbsp;ценами в&nbsp;любом количестве торговых точек. Контролируйте наличие товара</h5>
              </div>
              <div class="col">
                <img src="../assets/img/icon/icvendor10.webp" class="position-absolute col-3 col-md-2 px-3 start-100 translate-middle top-50" alt="">
              </div>
            </div>
            <div class="row position-relative mb-4">
              <div class="col-11 pe-3">
                <h4 class="fw-bold">Рабочее место продавца-кассира</h4>
                <h5>Простая программа для кассира на&nbsp;любом устройстве. Регистрация продаж, работа с&nbsp;весовым товаром, возвратами и&nbsp;онлайн-заказами. Подключение торгового оборудования. Печать квитанций. Офлайн-режим без доступа к&nbsp;интернету.</h5>
              </div>
              <div class="col">
                <img src="../assets/img/icon/icvendor11.webp" class="position-absolute col-3 col-md-2 px-3 start-100 translate-middle top-50" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-lg-5"></div>
    </div>
    <swiper
      :slides-per-view="1"
      :centered-slides="true"
      loop
      :speed="2000"
      :effect="slide"
    >
      <swiper-slide>
        <div class="row justify-content-center py-3" style="height:100%">
          <div class="col-10 p-lg-5 shad">
            <div class="mx-4 text-center pt-4">
              <h4 class="d-none d-lg-block">
                Интуитивно-понятный интерфейс не требует специального обучения, а настройка программы для ваших нужд занимает не более получаса.
              </h4>
              <h3 class="text-uppercase fw-bold my-5">
                  Проблемы и боли которые мы решаем:
              </h3>
            </div>
            <div class="row justify-content-center mb-4 px-4 px-lg-5">
              <div class="col-6 col-lg-4 text-center">
                <img src="../assets/img/icon/icvendor1.webp" alt="" class="img-fluid col-4">
                <h3 class="fw-bold">Контроль</h3>
              </div>
              <div class="col-4 text-center d-none d-lg-block">
                <img src="../assets/img/icon/icvendor1.webp" alt="" class="img-fluid col-4">
                <h3 class="fw-bold">Неправильный учет</h3>
              </div>
              <div class="col-6 col-lg-4 text-center">
                <img src="../assets/img/icon/icvendor1.webp" alt="" class="img-fluid col-4">
                <h3 class="fw-bold">Расходы</h3>
              </div>
              <div class="col-10 text-center d-lg-none">
                <img src="../assets/img/icon/icvendor1.webp" alt="" class="img-fluid col-3">
                <h3 class="fw-bold">Неправильный учет</h3>
              </div>
            </div>
            <div class="my-5"></div>
              
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="row justify-content-center py-3" style="height:100%">
          <div class="col-10 p-lg-5 shad">
            <div class="mx-4 mt-4 mt-lg-5 text-center ">
              <h3 class="text-uppercase fw-bold my-5">
                  Наши преимущества:
              </h3>
            </div>
            <div class="row justify-content-center mb-4">
              <div class="col-6 col-lg-4 text-center">
                <img src="../assets/img/icon/icvendor12.webp" alt="" class="img-fluid col-4">
                <h3 class="fw-bold">Бесплатно</h3>
                <h4>Бесплатный доступ для всех пользователей</h4>
              </div>
              <div class="col-4 text-center d-none d-lg-block">
                <img src="../assets/img/icon/icvendor13.webp" alt="" class="img-fluid col-4">
                <h3 class="fw-bold">Доступно</h3>
                <h4>На смартфоне, планшете</h4>
              </div>
              <div class="col-6 col-lg-4 text-center">
                <img src="../assets/img/icon/icvendor14.webp" alt="" class="img-fluid col-4">
                <h3 class="fw-bold">Просто</h3>
                <h4>Старт за 15 минут</h4>
              </div>
              <div class="col-10 text-center d-lg-none">
                <img src="../assets/img/icon/icvendor13.webp" alt="" class="img-fluid col-3">
                <h3 class="fw-bold">Доступно</h3>
                <h4>На смартфоне, планшете</h4>
              </div>
            </div>
            <div class="my-5"></div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="row justify-content-center" id="phones">
      <div class="col-10 col-xl-9 row justify-content-end mt-5 pt-5 my-lg-5 py-lg-5">
        <div class="col-lg-6 text-end my-lg-5 py-lg-5">
          <h4 class="fw-bolder text-uppercase border-end border-primary border-5 py-2 pe-3 pe-lg-5">
            <p class="fw-bold text-primary m-0">
              Многопользовательский<br>режим
            </p>
          </h4>
          <div class="te  pe-3 pe-lg-5">
            <h3 class="display-1">...</h3>
            <h4 class="fw-bold text-uppercase">Приглашайте других сотрудников</h4>
            <h4>Добавляйте несколько магазинов на один аккаунт</h4>
            <h3></h3>
          </div>
        </div>
      </div>
      <img class="img-fluid mb-5 pb-5 d-lg-none" src="../assets/img/vendorimg71.webp" alt="">
    </div>
    <swiper
      :slides-per-view="1"
      :centered-slides="true"
      loop
      :speed="2000"
      :effect="slide"
    >
      <swiper-slide>
        <div class="row justify-content-center">
          <div class="col-10 row align-items-center" id="bgphonecircle">
            <div class="col ps-lg-5 mb-5">
              <h3 class="text-uppercase fw-bold col-xl-7 border-bottom border-dark pb-3 ms-lg-5">
                Простой и удобный интерфейс
              </h3>
              <div class="text-center d-lg-none">
                <img class="img-fluid text-center col-8" src="../assets/img/vendorimg9.webp" alt="">
              </div>
            </div>
            <div class="col-6 d-none d-lg-block"><img src="../assets/img/Untitled.webp" class="img-fluid"></div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="row justify-content-center">
          <div class="col-10 row align-items-center" id="circlebgphone">
            <div class="col ps-lg-5 mb-5">
              <h3 class="text-uppercase fw-bold col-lg-7 border-bottom border-dark pb-3 ms-lg-5">
                Умный поиск и сортировка
              </h3>
              <div class="text-center d-lg-none">
                <img class="img-fluid text-center" src="../assets/img/vendorimg10.webp" alt="">
              </div>
            </div>
            <div class="col-6 d-none d-lg-block"><img src="../assets/img/Untitled.webp" class="img-fluid"></div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="col-10 position-relative text-center pt-lg-5">
      <img class="position-absolute start-0 top-50 translate-middle img-fluid col-7 d-none d-lg-block" src="../assets/img/Ellipse.webp">
      <h4 class="text-uppercase fw-bold my-5 py-lg-5">как подключитьсЯ?</h4>
      <div class="d-none d-lg-block">
        <img class="img-fluid col-9" src="../assets/img/Group459.webp" alt="">
      </div>
      <div class="row position-relative">
        <div class="col-2 d-lg-none">
          <img class="img-fluid" src="../assets/img/Group458.webp" alt="">
        </div>
        <div class="row col align-content-between tstart">
          <div class="col-lg mb-5">
            <h4 class="fw-bold mb-4">Пройдите регистрацию</h4>
            <h5>пройдите регистрация и пользуйтесь всем функционалом приложения бесплатно</h5>
          </div>
          <div class="col-lg mb-5">
            <h4 class="fw-bold mb-4">Загрузите товары</h4>
            <h5>Начните зарабатывать</h5>
          </div>
          <div class="col-lg">
            <h4 class="fw-bold mb-4">Начните зарабатывать</h4>
            <h5>Управляйте продажами и ценами</h5>
          </div>
        </div>
      </div>
      <div class="my-5 py-lg-5"><h1> </h1></div>
    </div>
    <div class="my-lg-5 mt-5"></div>
  
    <div class="col-10 shad p-4 p-lg-5">
      <div class="text-center">
        <h4 class="mb-4 text4">
          АВТОМАТИЗАЦИЯ ВАШЕГО БИЗНЕСА
        </h4>
        <h2 class="fw-bolder text4">БЫСТРО | ВЫГОДНО | УДОБНО</h2>
      </div>
      <div class="px-lg-5 pt-4 d-none d-lg-block">
        <div class="row align-items-center">
          <div class="col text-center">
            <img class="img-fluid px-2" src="../assets/img/vendorimg4.webp" alt="">
          </div>
          <div class="col-5 text-center">
            <img class="img-fluid px-5" src="../assets/img/vendorimg5.webp" alt="">
          </div>
          <div class="col text-center">
            <img class="img-fluid px-2" src="../assets/img/vendorimg6.webp" alt="">
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col text-center">
            <h4 class="fw-bold text-uppercase">Перестаньте вести учет в тетради</h4>
          </div>
          <div class="col-5 text-center">
            <h4 class="fw-bold text-uppercase">Искорените воровство</h4>
          </div>
          <div class="col text-center">
            <h4 class="fw-bold text-uppercase">Защитите данные</h4>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col text-center">
            <h6 class="fw-bold px-4">Теперь все отчеты в вашем телефоне онлайн</h6>
          </div>
          <div class="col-5 text-center px-4">
            <h6 class="fw-bold px-3">Gosu Vendor помогает отслеживать и устранять воровство на рабочих местах с помощью аналитики поведения сотрудников и модулей мониторинга и аналитики.</h6>
          </div>
          <div class="col text-center">
            <h6 class="fw-bold px-5">Облачный сервис хранит все данные</h6>
          </div>
        </div>
      </div>
      <div class="pt-4 d-lg-none">
        <div class="col text-center">
          <img class="img-fluid col-8 px-2" src="../assets/img/vendorimg4.webp" alt="">
          <h4 class="fw-bold text-uppercase">Перестаньте вести учет в тетради</h4>
          <h6 class="fw-bold px-4">Теперь все отчеты в вашем телефоне онлайн</h6>
        </div>
        <div class="col text-center">
          <img class="img-fluid col-8 px-2" src="../assets/img/vendorimg5.webp" alt="">
          <h4 class="fw-bold text-uppercase">Искорените воровство</h4>
          <h6 class="fw-bold px-3">Gosu Vendor помогает отслеживать и устранять воровство на рабочих местах с помощью аналитики поведения сотрудников и модулей мониторинга и аналитики.</h6>
        </div>
        <div class="col text-center">
          <img class="img-fluid col-8 px-2" src="../assets/img/vendorimg6.webp" alt="">
          <h4 class="fw-bold text-uppercase">Защитите данные</h4>
          <h6 class="fw-bold px-5">Облачный сервис хранит все данные</h6>
        </div>
      </div>
    </div>
    <div class="my-5 py-lg-4"></div>
  </section>
<div class="container-fluid bg-primary text-light p-0">
  <section class="row justify-content-center">
    <div class="col-10 row align-items-center my-5">
      <div class="col-4 d-none d-lg-block">
        <img class="img-fluid" src="../assets/img/vendorlogo.webp" alt="">
      </div>
      <div class="col-lg-8">
        <h2 class="text-uppercase fw-bold mb-5 tcenter">Сопровождение </h2>
        <img class="img-fluid d-lg-none" src="../assets/img/vendorlogo.webp">
        <!-- <h4 class="tcenter">Gosu Vendor для одной точки продаж с&nbsp;одним сотрудником —&nbsp;на&nbsp;1&nbsp;месяц.</h4> -->
        <h4 class="fw-bold tcenter mt-3">Поможем установить, настроить, запустить автоматизацию и проконсультируем по приложению</h4>
      
      </div>
    </div>
    
  </section>

</div>



<section class="row justify-content-center">
  <!-- <div class="my-5 py-lg-4"></div> -->
  <!-- <div class="col-10 col-md-7 col-lg-9 col-xl-8 py-5 shad">
    <div class="row">
      <div class="col-lg-6 p-0">
        <div class="tcenter ps-md-5">
          <img src="../assets/img/logoOrigBP.webp" class="col-2 col-lg-3 mb-4 mb-lg-5 ps-lg-5">
        </div>
        <div class="ps-5">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">1 мес.</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">3 мес.</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">1 год</button>
            </li>
          </ul>
        </div>
        <div class="d-flex ps-5">
          <h1 class="fw-bold display-5 me-3">Gosu <p class="fw-bold display-5 text-primary d-inline">Vendor</p></h1>
        </div>
        <div class="row align-items-center mt-4 px-4 d-lg-none">
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Низкий порог входа</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Интуитивно понятный интерфейс</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Многопользовательский режим</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Умный поиск и сортировка</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Кастомизация платежных квитанций</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Облачный сервис - хранение данных</h6>
        </div>
        <div class="text-light  py-4 tab-content" id="pills-tabContent">
          <img src="../assets/img/elemPricePrimaryFree.webp" class="img-fluid col-11 col-md-9 col-lg-11">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0"><h1 class="display-5 fw-bold my-2 my-lg-3">4 990 ₸</h1></div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0"><h1 class="display-5 fw-bold my-2 my-lg-3">27 990 ₸</h1></div>
          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0"><h1 class="display-5 fw-bold my-2 my-lg-3">49 990 ₸</h1></div>
          
        </div>
        <div class="mt-5 ps-md-5 tcenter">
          <a href="#geoform">
            <button class="btn btn-outline-primary px-5 py-3">
              <h6 class="fw-bold text-uppercase m-0 mx-2">приобрести</h6> 
            </button>
          </a>
        </div>
      </div>
      <div class="col-6 row align-items-center pe-5 d-none d-lg-flex">
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Низкий порог входа</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Интуитивно понятный интерфейс</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Многопользовательский режим</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Умный поиск и сортировка</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Кастомизация платежных квитанций</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Облачный сервис - хранение данных</h6>
      </div>
    </div>
  </div> -->
  <!-- <div class="my-lg-5"></div> -->
  
  </section>
  <PartnersVue />
  
  <GeoForm />
  
  <QuestionVue />
  
  <FooterVue />
  
  </main>
  </template> 
      
  <script>
  import HeaderMenu from '@/components/HeaderMenu.vue'
  import PartnersVue from '@/components/PartnersVue.vue'
  import GeoForm from '@/components/GeoForm.vue'
  import QuestionVue from '@/components/QuestionVue.vue'
  import FooterVue from '@/components/FooterVue.vue'
  import { Autoplay } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/autoplay';
  
  export default{
      name:'AboutUs',
      components:{
        HeaderMenu,
        PartnersVue,
        GeoForm,
        QuestionVue,
        FooterVue,
        Swiper,
        SwiperSlide,
      },
      Autoplay,
      swipbreakp:{
        500:{
          slidesPerView: 6.5,
        }
      }
  }
  </script>
  
  <style scoped>
  #phones{
    background-image: url(../assets/img/vendorimg7.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .price{
    background-image: url(../assets/img/elemPricePrimary.webp);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .duble{
    background-image: url(../assets/img/dublee.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  #bgbg{
    background-image: url(../assets/img/vendorimg7.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  #bgphonecircle{
    background-image: url(../assets/img/vendorimg3.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;

  }
  #circlebgphone{
    background-image: url(../assets/img/vendorimg8.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;

  }
  @media (max-width:992px) {
  .duble{
    background-image: none;
  }
  #phones{
    background-image: none;
  }  
  #bgphonecircle{
    background-image: none;

  }
  #circlebgphone{
    background-image: none;

  }
}
</style>
