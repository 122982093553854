<template>
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">
      <div class=" mt-5 pt-5">
        <div class="border-start border-primary border-5 py-2 ps-4 ps-lg-5">
          <h3 class="fw-bolder">Мы работаем над проектами для лучших</h3>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid p-0">
    <swiper
      :slides-per-view="2.5"
      :space-between="20"
      :modules="[Autoplay]"
      loop
      Parallax
      :autoplay="{
          delay: 0.1,
          disableOnInteraction: false
        }"
      :speed="2000"
      :effect="slide"
      :breakpoints="swipbreakp"
    >
      <template  v-for="slide in slides" :key="slide">
        <swiper-slide>
          <div class = "p-4">
            <img :src="slide" class="img-fluid">
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>


<script>
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';

export default{
  name: 'home-page',
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
      return {
        Autoplay,
        swipbreakp:{
          577:{
            slidesPerView: 3,
          },
          768:{
            slidesPerView: 4,
          },
          992:{
            slidesPerView: 5.4,
          },
          1200:{
            slidesPerView: 6.5,
          },
        }
      };
    },
  data() {
    return {
      slides: [
                '/assets/client/atasu.webp',
                '/assets/client/terranova.webp',
                '/assets/client/Dami.webp',
                '/assets/client/constella.webp',
                '/assets/client/COLA.webp',
                '/assets/client/Hobby.webp',
                '/assets/client/buy.webp',
                '/assets/client/truck.webp',
                '/assets/client/shline.webp',
                '/assets/client/scania.webp',
                '/assets/client/askona.webp',
                '/assets/client/rg.webp',
                '/assets/client/kaz.webp',
                '/assets/client/Springfield.webp',
                '/assets/client/hatber.webp',
              ]
    }
  }
}
</script>
