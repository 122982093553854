<template>
<div>
<main class="d-flex align-items-center flex-column">
<HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">

      <div class="col-lg-6 d-grid align-content-between mt-5 pt-lg-5">
        <h4 class="mb-5 mb-lg-4"></h4>
        <div class="d-flex">
          <h1 class="display-2 fw-bolder me-3">Gosu</h1>
          <h1 class="display-2 fw-bolder text-primary">GBA</h1>
        </div>
        <div class="text-center d-lg-none my-5">
          <img class="col-9 img-fluid" src="../assets/img/gbaimg1.webp" alt="">
        </div>
        <div class="my-5">
          <h4 class="border-start border-primary border-5 text-uppercase ps-4 ps-lg-5 mb-5 pe-4">
            Интуитивно-понятный интерфейс не требует специального обучения
          </h4>
          <a href="https://api.whatsapp.com/send?phone=77788181168" target="_blank">
            <button class="btn btn-outline-primary text-uppercase fw-bold px-5 py-3">
              <h6 class="fw-bold m-0 mx-3">приобрести</h6> 
            </button>
          </a>
        </div>
      </div>
      <div class="col-6 text-end fullHeight d-none d-lg-flex">
        <img class="img-fluid" src="../assets/img/gbaimg1.webp" alt="">
      </div>
      <div class="my-lg-5"></div>
    </div>
    <div class="col-10 mt-lg-5 p-3 p-lg-5 shad">
      <div class="mx-4 mt-4 mt-lg-5 mb-4 text-center text-uppercase">
        <h2 class="text-primary fw-bolder d-inline-block me-2">
          GOSU GBA  -  
        </h2>
        <h2 class="fw-bolder d-inline-block">
          программное обеспечение для ведения
        </h2>
        <h2 class="fw-bolder d-inline-block">
          базового учета на предприятии
        </h2>
      </div>
    </div>

    <div class="my-5"></div>

    <div class="col-10 col-xl-9 position-relative row">
      <div class="col position-relative d-none d-lg-block" id="gbaimg">
        <img class="img-fluid position-absolute translate-middle start-50 top-50 pe-5" src="../assets/img/gbaimg2.webp" alt="">
      </div>
      <div class="col-lg-6 d-flex flex-column align-items-end text-end">
        <div class="border-end border-primary border-5 text-uppercase mb-5 py-2 pe-4 pe-lg-5">
          <h4 class="fw-bold d-inline-block m-0 me-2">
            Почему нужна
          </h4>
          <h4 class="fw-bold d-inline-block m-0 text-primary">
            интеграция?
          </h4> 
        </div>
        <div class="col-lg-10 pe-4 pe-lg-5 ps-0">
          <h4>
            Благодаря интеграции c POS , вы сократите время обработки заказа, что в итоге позволит сократить количество невыполненных заказов на 80% и увеличить оборот на 25%.
          </h4>
        </div>
        <div class="d-lg-none" id="gbaimg">
          <img class="img-fluid" src="../assets/img/gbaimg2.webp" alt="">
        </div>
        <div class="my-lg-5"></div>
      </div>
      <div class="col-9 col-xl-7 position-absolute end-0 bottom-0 d-none d-lg-flex">
        <div class="col row align-items-center">
          <div class="col-4 text-center rounded-pill bg-primary py-3">
            <h2 class="my-3 text-light fw-bold">
              80%
            </h2>
          </div>
          <div class="col ps-4">
            <h3>
              Сокращение отказов
            </h3>
          </div>
        </div>
        <div class="col row align-items-center">
          <div class="col-4 text-center rounded-pill bg-primary py-3">
            <h2 class="my-3 text-light fw-bold">
              80%
            </h2>
          </div>
          <div class="col ps-4">
            <h3>
              Увеличение заказов
            </h3>
          </div>
        </div>
      </div>
      <div class="my-lg-3"></div>
    </div>

    <div class="my-lg-5 mt-5"></div>

    <div class="col-10 shad p-4 p-lg-5">
      <div class="text-center mt-4 px-lg-5">
        <h2 class="fw-bolder mb-4">ИНТЕГРАЦИЯ С POS</h2>
        <h4 class="px-lg-5 mx-lg-5">GBA легко подключается к POS и обеспечивает автоматизированный обмен меню и заказами с агрегаторами доставки еды.</h4>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4">     <img class="img-fluid px-2" src="../assets/img/icon/ictsd6.webp" alt=""></div>
        <div class="col-lg-4 px-5"><img class="img-fluid px-2" src="../assets/img/icon/ictsd7.webp" alt=""></div>
      </div>
    </div>
    <div class="my-lg-5 mt-5"></div>
  </section>

  <div class="d-flex justify-content-center borderdash">
    <section class="row justify-content-center">
      <div class="col-10 row justify-content-between p-lg-5">
        <div class="col-lg-4 text-center row align-items-center my-4">
          <div class="col-4 col-lg-12">
            <img src="../assets/img/icon/icgba1.webp" class="img-fluid col-9 col-lg-5 mb-3">
          </div>
          <div class="col-8 col-lg-12 ">
            <h4 class="fw-bolder text-uppercase">Без ошибок</h4>
            <h5 class="">Интеграция исключает ошибки, которые возможны при ручном вводе заказа.</h5>
          </div>
        </div>
        <div class="col-lg-4 text-center row align-items-center py-4 borderddd">
          <div class="col-4 col-lg-12">
            <img src="../assets/img/icon/icgba2.webp" class="img-fluid col-9 col-lg-5 mb-3">
          </div>
          <div class="col-8 col-lg-12 ">
            <h4 class="fw-bolder text-uppercase">Без ручной работы</h4>
            <h5 class="px-4">Интеграция делает все автоматически. Обмен меню и прием заказов.</h5>
          </div>
        </div>
        <div class="col-lg-4 text-center row align-items-center my-4">
          <div class="col-4 col-lg-12">
            <img src="../assets/img/icon/icgba3.webp" class="img-fluid col-9 col-lg-5 mb-3">
          </div>
          <div class="col-8 col-lg-12 ">
            <h4 class="fw-bolder text-uppercase">Высокая скорость</h4>
            <h5 class="px-4">Заказ будет передан в POS за 1 секунду. Никто не забудет про заказ.</h5>
          </div>
        </div>

      </div>
    </section>
  </div>

  <section class="row justify-content-center">
    <div class="my-5 py-lg-4"></div>
    <div class="col-10 col-lg-8 py-5 shad">
      <div class="row">
        <div class="col-lg-6 p-0">
          <div class="tcenter">
            <img src="../assets/img/logoOrigBP.webp" class="col-2 col-lg-3 mb-3 mb-lg-5 ps-lg-5">
          </div>
          <h5 class="fw-bold ps-lg-5 tcenter">1 касса</h5>
          <div class="ps-lg-5 tcenter">
            <h1 class="fw-bold display-5 me-3">Gosu <p class="fw-bold text-primary d-inline">GBA</p></h1>
          </div>
          <div class="row align-items-center mt-4 px-4 d-lg-none">
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
            <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          </div>
          <div class="price text-light text-center py-4">
            <!-- <img src="../assets/img/elemPricePrimary.webp" class="img-fluid col-11"> -->
            <h1 class="display-5 fw-bold my-2 my-xl-3 ps-lg-3">5 000 ₸</h1>
          </div>
          <div class="mt-4 ps-lg-5 tcenter">
            <a href="https://api.whatsapp.com/send?phone=77788181168" target="_blank">
              <button class="btn btn-outline-primary px-5 py-3">
                <h6 class="fw-bold text-uppercase m-0 mx-2">приобрести</h6> 
              </button>
            </a>
          </div>
        </div>
        <div class="col-6 row align-items-center pe-5 d-none d-lg-flex">
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
          <i class="bi bi-check2 h1 text-primary col-1"></i><h6 class="col-11 ps-3">Режимы работы официанта, кассира и администратора зала</h6>
        </div>
      </div>
    </div>
    <div class="my-lg-5"></div>


















  </section>

  <PartnersVue />
  
  <GeoForm />
    
  <QuestionVue />

  <FooterVue />

</main>
</div>
</template>
    
<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name:'AboutUs',
  components:{
    HeaderMenu,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  }
}
</script>

<style scoped>

.price{
  background-image: url(../assets/img/elemPricePrimary.webp);
  background-size: contain;
  background-repeat: no-repeat;
}
.borderdash{
  width: 100%;
  border-top: 1px dashed #65B2F0;
  border-bottom: 1px dashed #65B2F0;
}
@media(max-width:500px){
  .borderddd{
  width: 100%;
  border-top: 1px dashed #65B2F0;
  border-bottom: 1px dashed #65B2F0;
  }
}
</style>
