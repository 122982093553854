<template>
  <div class="col-lg-6 text-light my-5 mt-lg-0 pe-lg-4 d-none d-lg-block">
    <router-link to="/AskonaPage" class="text-decoration-none text-light">
      <div class="card rounded-5 border-0 mb-2" id="project1">
        <div class="card-body p-4 p-lg-5">
          <div class="card-title">
            <h2 class="fw-bold mb-4">PWA для “Askona”</h2>
          </div>
          <div class="card-text">
            <h5>Приложение для менеджера по продажам</h5>
          </div>
          <div class="card-text p-3 p-lg-5">
            <img class="img-fluid" src="../assets/img/project2.webp" alt="">
          </div>
        </div>
      </div>
    </router-link>
    <div class="mb-5 mt-4 d-lg-none d-flex justify-content-end">
      <button type="button" class="btn btn-outline-primary text-uppercase px-5 py-3 rounded-pill">
        <h6 class="fw-bold m-0 mx-lg-3">смотреть все</h6>
      </button>
    </div>
  </div>
  <div class="col-lg-6 pt-lg-5 ps-lg-4 text-light mb-5 pb-5 d-none d-lg-block">
    <!-- <router-link to="#" class="text-decoration-none text-light"> -->
      <div class="card rounded-5 border-0 mb-2 mt-4" id="project2" style="cursor:pointer">
        <div class="card-body p-4 p-lg-5">
          <div class="card-title">
            <h2 class="fw-bold mb-4">PWA для “Coca-Cola İçecek”</h2>
          </div>
          <div class="card-text">
            <h5>Приложение, которое позволяет осуществлять контроль и управление мерчендайзерами.</h5>
          </div>
          <div class="card-text p-3 p-lg-5">
            <img class="img-fluid" src="../assets/img/project1.webp" alt="">
          </div>
        </div>
      </div>
    <!-- </router-link> -->
  </div>
  <div class="d-lg-none">
    <swiper 
      :slides-per-view="1"
      :centered-slides="true"
      :space-between="50"
      :modules="[Autoplay]"
      loop
      Parallax
      :autoplay="{
          delay: 5000,
          disableOnInteraction: false
        }"
      :speed="3000"
      :effect="slide"
    >
      <swiper-slide>
        <div class="text-light my-5">
          <router-link to="/AskonaPage" class="text-decoration-none text-light">
            <div class="card rounded-5 border-0 mb-2" id="project1">
              <div class="card-body p-4 p-lg-5">
                <div class="card-title">
                  <h2 class="fw-bold mb-4">PWA для “Askona1”</h2>
                </div>
                <div class="card-text">
                  <h5>Приложение для менеджера по продажам</h5>
                </div>
                <div class="card-text p-3 p-lg-5">
                  <img class="img-fluid" src="../assets/img/project2.webp" alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="text-light my-5">
          <router-link to="#" class="text-decoration-none text-light">
            <div class="card rounded-5 border-0 mb-2 mt-4" id="project2">
              <div class="card-body p-4 p-lg-5">
                <div class="card-title">
                  <h2 class="fw-bold mb-4">PWA для “Coca-Cola İçecek1”</h2>
                </div>
                <div class="card-text">
                  <h5>Приложение, которое позволяет осуществлять контроль и управление мерчендайзерами.</h5>
                </div>
                <div class="card-text p-3 p-lg-5">
                  <img class="img-fluid" src="../assets/img/project1.webp" alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';

export default{
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      Autoplay,
    };
  },
  data(){
    return{
      blocks: {
        msg1:'Дом - Проекты',
        msg2:'Дом - Проекты',
        msg3:'Дом - Проекты',
        msg4:'Дом - Проекты',
        src:'../assets'
      }
    }
  }
}

</script>

<style scoped>
#project1{
background: linear-gradient(180deg, #65B2F0 0%, rgba(101, 178, 240, 0) 100%);
}
#project2{
background: linear-gradient(180deg, #65B2F0 0%, rgba(101, 178, 240, 0) 100%);
}
#project3{
background: linear-gradient(180deg, #44A0CB 0%, rgba(68, 160, 203, 0) 100%);
}
#project4{
background: linear-gradient(180deg, #2E94B2 0%, rgba(46, 148, 178, 0) 100%);
}
#project5{
background: linear-gradient(180deg, #148696 0%, rgba(20, 134, 150, 0) 100%);
}
#project6{
background: linear-gradient(180deg, #027C81 0%, rgba(2, 124, 129, 0) 100%);
}

</style>
  