<template>
<div class="">

<main class="d-flex align-items-center flex-column">
<HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">

      <div class="col-lg-5 d-grid mt-5 pt-lg-5">
        <h4 class="fw-normal mb-5 mb-lg-0">

        </h4>
        <h1 class="display-3 fw-bolder my-5 my-lg-0">O <a class="text-decoration-none fw-bold">GOSU</a> </h1>
        <h4>
          Профессионализм и клиентоориентированный подход к&nbsp;делу, от первого контакта до завершения проекта
        </h4>
      </div>
      <div class="col-7 text-end fullHeight d-none d-lg-flex">
        <img class="img-fluid" src="../assets/img/aboutus2.webp" alt="">
      </div>

      <div class="row mb-xl-5 py-lg-5">
        <div class="my-5 my-lg-0"></div>
        <div class="col-lg-3 col-xl-5 mb-5 mb-lg-0">
          <h4 class="text-uppercase fw-bold border-start border-primary border-5 py-2 pt-lg-1 pb-lg-5 ps-4 ps-lg-5">
            Кто мы
          </h4> 
        </div>
        <div class="col-lg-9 col-xl-7">
          <h6 class="text-uppercase">
            Gosu - это динамично развивающаяся IT-компания, специализирующаяся на разработке, внедрении и сопровождении продуктов на платформе 1с.
            <br /><br />Компания была основана в 2016 году.
            <br /><br />За прошедший период Gosu развила направления мобильной разработки, веб-разработки, а также делает свои первые шаги в области искусственного интеллекта. 
            <br /><br />Имея в штате высококвалифицированных специалистов с большим опытом работы в сложных проектах мы готовы ответить на все современные вызовы в сфере IT. 
            <br /><br />Параллельно мы развиваем свои собственные продукты такие как Gosu TSD, Gosu GBA, Gosu Vendor, Gosu Kassa.
            <br /><br />Наши решения гарантируют устойчивое развитие бизнеса клиента и делают его сильнее.
            <br /><br />Мы гордимся нашими проектами внедренные в такие крупные компании как Askona, Coca Cola, Mcdonald's, Atasu и другие.
          </h6>
        </div>
      </div>
      <div class=" text-uppercase my-5 py-xl-5">
        <swiper 
          :slides-per-view="2.2"
          :space-between="1"
          :modules="[Autoplay]"
          loop
          Parallax
          :autoplay="{
              delay: 0.5,
              disableOnInteraction: false
            }"
          :speed="3000"
          :effect="slide"
          :breakpoints="swipbreakp"
        >
          <swiper-slide>
            <div>
              <p class="display-2 fw-bold">18+</p>
              <h5>награды</h5>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <p class="display-2 fw-bold">25+</p>
              <h5>члены команды</h5>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <p class="display-2 fw-bold">6+</p>
              <h5 class="pe-4 pe-md-5">лет на&nbsp;рынке</h5>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <p class="display-2 fw-bold">100+</p>
              <h5>проекты</h5>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="d-flex justify-content-between text-uppercase my-5 py-lg-5">
        <div class="col-5 col-lg-2">
          <p class="display-2 fw-bold">18+</p>
          <h5>награды</h5>
        </div>
        <div class="col-5 col-lg-2">
          <p class="display-2 fw-bold">25+</p>
          <h5>члены команды</h5>
        </div>
        <div class="col-5 col-lg-2">
          <p class="display-2 fw-bold">6+</p>
          <h5>лет на рынке</h5>
        </div>
        <div class="col-5 col-lg-2">
          <p class="display-2 fw-bold">100+</p>
          <h5>проекты</h5>
        </div>
      </div> -->

      <div class="d-flex mb-xl-5 py-5">
        <div class="col-6 pb-5 pe-5 d-none d-lg-block">
          <img class="img-fluid" src="../assets/img/aboutus1.webp" alt="">
        </div>
        <div class="col-12 col-lg-6 text-end text-md-start text-lg-end">
          <div class="border-end border-primary border-5 text-end mb-5 py-2 pe-4 pe-lg-5">
            <h2 class="text-uppercase fw-bold">Услуги Gosu</h2>
          </div>
          <div class="row abotserv">
            <div class="col-md-6 col-lg-5 d-flex flex-column align-items-lg-end pt-5 pt-md-0 pt-xxl-5">
              <h5 class="mb-4 col-lg-8">Мобильные приложения</h5>
              <h5 class="mb-4 ">Веб-сайты</h5>
              <h5 class="mb-4 ">Ux/Ui-дизайн</h5>
            </div>
            <div class="col-md-6 col-lg-7 d-flex flex-column align-items-lg-end pt-xxl-5 pb-5 pb-md-0">
              <h5 class="mb-4 col-lg-8">Разработка и внедрение 1с</h5>
              <h5 class="mb-4 ">Сопровождение 1с</h5>
              <h5 class="mb-lg-4 ">Интеграция с 1с</h5>
            </div>
          </div>
        </div>
      </div>
      
      <div>
        <div class="border-start border-primary border-5 text-uppercase mb-5 py-2 ps-4 ps-lg-5">
          <h4 class="fw-bold">Наши принципы</h4>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 pe-md-4">
        <h5 class="mb-4 fw-light">01</h5>
        <h5 class="mb-4 text-uppercase fw-bold">Работа на результат</h5>
        <h5 class="mb-5 pb-4 pb-md-0">Клиент должен получать прибыль: финансовую или имиджевую. Пункт назначения важнее маршрута.</h5>
      </div>
      <div class="col-md-6 col-lg-4 pe-lg-4">
        <h5 class="mb-4 fw-light">02</h5>
        <h5 class="mb-4 text-uppercase fw-bold">Анализировать</h5>
        <h5 class="mb-5 pb-4 pb-md-0">Бизнес клиента, его конкурентов, ваши результаты. успешная стратегия не строится на догадках.</h5>
      </div>
      <div class="col-md-6 col-lg-4 pe-md-4">
        <h5 class="mb-4 fw-light">03</h5>
        <h5 class="mb-4 text-uppercase fw-bold">Не пропустите мелочи</h5>
        <h5 class="mb-5 pb-4 pb-md-0">Перфекционизм на каждом этапе. Небольшие действия имеют большие последствия.</h5>
      </div>
      <div class="col-md-6 col-lg-4 pe-lg-4">
        <h5 class="mb-4 fw-light">04</h5>
        <h5 class="mb-4 text-uppercase fw-bold">Положитесь на свою силу</h5>
        <h5 class="mb-5 pb-4 pb-md-0">У нас работают специалисты любого профиля. Если вы хотите гордиться результатом – достигните его сами.</h5>
      </div>
      <div class="col-md-6 col-lg-4 pe-md-4">
        <h5 class="mb-4 fw-light">05</h5>
        <h5 class="mb-4 text-uppercase fw-bold">Идти навстречу клиенту</h5>
        <h5 class="mb-5 pb-4 pb-md-0">С точки зрения сроков, суммы и формы оплаты. Уметь вести переговоры – значит уметь работать.</h5>
      </div>
      <div class="col-md-6 col-lg-4">
        <h5 class="mb-4 fw-light">06</h5>
        <h5 class="mb-4 text-uppercase fw-bold">Улучшать</h5>
        <h5 class="mb-lg-5 pb-lg-4">Курсы, тренинги, специализированные конференции. Если вы не будете двигаться вперед, вы останетесь позади.</h5>
      </div>

    </div>
  </section>

  <PartnersVue />
  
  <GeoForm />
    
  <QuestionVue />

  <FooterVue />

</main>

</div>
</template>



<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'
import { Autoplay, EffectFade} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";
export default{
  name:'AboutUs',
  components:{
    HeaderMenu,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue,
    Swiper,
    SwiperSlide,
  },
  setup() {
      return {
        Autoplay,
        EffectFade,
        swipbreakp:{
          380:{
            slidesPerView: 2.8,
            centeredSlides: false,
          },
          577:{
            slidesPerView: 3.3,
            centeredSlides: false,
          },
          769:{
            slidesPerView: 4,
            centeredSlides: false,
            autoplay:false
          }
        }
      };
    }
}
</script>

<style scoped>
@media(max-width:500px){
  /* .abotserv{
    background-image: url('../assets/img/aboutus1.webp');
    background-size: cover;
  } */
}
</style>
