<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
.hhh{
    height: 100% ;
}
@font-face {
    font-family: 'Gilroy-Regular ☞';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Regular ☞'), url('/public/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy-Bold ☞';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Bold ☞'), url('/public/fonts/Gilroy/Gilroy-Bold.woff') format('woff');
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Gilroy-Regular ☞';
}
.fw-bold, .fw-bolder{
    font-family: 'Gilroy-Bold ☞';
}
section, #section{
    width: 100%;
    max-width: 1440px;
}
:root{
    --bs-primary: #65b2f0;
    --bs-primary-rgb: 101,178,240;
    --bs-gradient: linear-gradient(180deg, rgb(255 255 255 / 0%), rgb(255 255 255));
    --bs-success: #007b7f;
    --bs-success-rgb: 0, 123, 127;
    --bs-link-color: #65b2f0;
    --bs-link-hover-color: #2d75af;
}

.btn-outline-primary {
    --bs-btn-color: #65b2f0;
    --bs-btn-border-color: #65b2f0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #65b2f0;
    --bs-btn-hover-border-color: #65b2f0;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #65b2f0;
    --bs-btn-active-border-color: rgb(101, 178, 240);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(76, 165, 238, 0.801);
    --bs-btn-disabled-color: #65b2f0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #65b2f0;
    --bs-gradient: none;
}
.border-green{
    --bs-border-color: #007b7f;
}
.cardservices{
    background: #007b7f;
}
.btn-outline-green {
    --bs-btn-color: #007b7f;
    --bs-btn-border-color: #007b7f;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #007b7f;
    --bs-btn-hover-border-color: #007b7f;
    --bs-btn-focus-shadow-rgb: 0, 123, 127;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #007b7f;
    --bs-btn-active-border-color: #007b7f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #007b7f;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #007b7f;
    --bs-gradient: none;
}
.text-green{
    color: #007b7f;
}
.btn-green {
    --bs-btn-color: #fff;
    --bs-btn-bg: #007b7f;
    --bs-btn-border-color: #007b7f;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #056669;
    --bs-btn-hover-border-color: #056669;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #056669;
    --bs-btn-active-border-color: #056669;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #007b7f;
    --bs-btn-disabled-border-color: #007b7f;
}
.bg-green{
    background: #007b7f;
}
.btn{
    --bs-btn-border-width: 0px;
    box-shadow: 0px 0px 8px rgba(101, 177, 240, 0.829);
}
.fullHeight{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.shad{
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.row {
    --bs-gutter-x: 0rem;
}
.swipcard{
    height: 100% !important;
}
@media(max-width:576px){
    main{
        overflow: hidden;
    }
    .tcenter{
        text-align: center;
    }
    .tstart{
        text-align: start !important;
    }
    .text3{
        font-size: 21px;
    }
    .text4{
        font-size: 16px;
    }
    .text33{
        font-size: 28px;
    }
    .text55{
        font-size: 22px;
    }
}
.nav-pills {
    --bs-nav-pills-link-active-bg: #65b2f0;
}

</style>
