<template>
<div>
<main class="d-flex align-items-center flex-column">
  <HeaderMenu />
  <section class="row justify-content-center">
    <div class="col-10 col-xl-9 row p-0">

      <div class="col-lg-6 d-grid mt-5 mb-4 my-lg-5 pt-lg-5">
        <div class="mb-5 mb-lg-0">
          <h4>
            {{ $t('homepage.title') }}<img src="../assets/img/icon/icraket.webp" class="img-fluid col-1">
          </h4>
        </div>
        <div class="">
          <h1 class="fw-bolder display-4">{{ $t('homepage.theme.th1') }}</h1>
          <h1 class="fw-bolder display-4 text-primary">{{ $t('homepage.theme.th2') }}</h1> 
          <h1 class="fw-bolder display-4">{{ $t('homepage.theme.th3') }}</h1>
        </div>
        <div>
          <img class="d-lg-none img-fluid" src="../assets/img/team/teamimg1.webp" alt="">
        </div>
        <div>
          <h5>{{ $t('homepage.subtitle.subt1') }} | {{ $t('homepage.subtitle.subt2') }} | 1C | ERP | {{ $t('homepage.subtitle.subt3') }} | {{ $t('homepage.subtitle.subt4') }}</h5>
          <div class="">
            <button class="btn btn-outline-primary text-uppercase mt-5 px-5 py-3" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1">
              <h6 class="fw-bold m-0 mx-3">Обсудить проект</h6>
            </button>
            <FormS />
          </div>
        </div>
      </div>
      <div class="col-6 d-none d-lg-block imgteam fullHeight">
      </div>

      <div class="my-4 my-lg-5">
        <div class="border-start border-primary border-5 py-2 ps-3 ps-lg-5 ">
          <h4 class="fw-bolder d-inline">Gosu</h4>
          <h4 class="d-inline"> - это динамично развивающаяся IT-компания, специализирующаяся на разработке, внедрении и сопровождении продуктов на платформе 1с.</h4>
          <h4 class="mt-3">Компания была основана в 2016.</h4> 
          <h4 class="mt-3">За прошедший период Gosu развила направления мобильной разработки, веб-разработки, aктивно развиваем направление AI(Искусственный интеллект).</h4>
        </div>
      </div>
      
      <div class="my-4 my-lg-5">
        <div class="text-end border-end border-primary border-5 py-2 pe-3 pe-lg-5">
          <h4 class="fw-bolder">НАШИ ПРОДУКТЫ</h4>
        </div>
      </div>
      <ProductsVue />
      <div class="d-flex d-lg-none justify-content-center" id="scene">
        <div class="col-11 mb-lg-5 pb-5 text-end px-2">
          <button class="btn btn-outline-primary rounded-pill text-uppercase px-5 py-3" type="button">
            <h6 class="fw-bold m-0 mx-lg-3">смотреть все</h6>
          </button>
        </div>
      </div>
      <div class="text-end mt-5">
        <div class="border-end border-primary border-5 py-2 pe-3 pe-lg-5">
          <h4 class="fw-bolder">НАШИ ПРОЕКТЫ</h4>
        </div>
      </div>
      <ProjectsVue />
      <GosuTeam />
      <div class="my-5">
        <div class="text-end border-end border-primary border-5 py-2 pe-3 pe-lg-5">
          <h4 class="fw-bolder">НАШИ УСЛУГИ</h4>
        </div>
      </div>
      <Services />
      <!-- <div class="mb-5 mt-4">
        <button class="btn btn-outline-primary rounded-pill text-uppercase px-5 py-3" type="button">
          <h6 class="fw-bold m-0 mx-3">смотреть все</h6>
        </button>
      </div> -->
    </div>
  </section>
  
  <PartnersVue />
  <GeoForm />
  <QuestionVue />
  <FooterVue />
</main>
</div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue'
import FormS from '@/components/FormSubmit.vue'
import ProductsVue from '@/components/ProductsVue.vue'
import ProjectsVue from '@/components/ProjectsVue'
import GosuTeam from '@/components/GosuTeam.vue'
import Services from '@/components/ServicesVue.vue'
import PartnersVue from '@/components/PartnersVue.vue'
import GeoForm from '@/components/GeoForm.vue'
import QuestionVue from '@/components/QuestionVue.vue'
import FooterVue from '@/components/FooterVue.vue'

export default{
  name: 'home-page',
  components:{
    HeaderMenu,
    FormS,
    ProductsVue,
    ProjectsVue,
    GosuTeam,
    Services,
    PartnersVue,
    GeoForm,
    QuestionVue,
    FooterVue
  },
  setup() {
      return {

      };
    },
  data() {
    return {

    }
  },
  mounted(){
  }
}
</script>

<style>
.imgteam{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/img/team/teamimg1.webp);
}
/* .imgteam:hover{
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../assets/img/TeamColor.webp);
} */
</style>


