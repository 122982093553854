import en from './en.json'
import ru from './ru.json'
import kaz from './kaz.json'

export const defaultLocale = 'ru'

export const languages = {
    en, 
    ru, 
    kaz
}