<template>
  <div class="container-fluid d-flex justify-content-center sticky-top bg-light p-0">
    <section class="row justify-content-center sticky-top">
      <nav class="navbar navbar-expand-lg col-10 col-xl-9">
        <div class="container-fluid p-0">
          <a class="navbar-brand col-1" href="/">
            <img 
              class="img-fluid col-sm-9 col-md-6 col-lg-5" 
              src="../assets/img/logoOrigBPC.webp" 
              alt=""
            >
          </a>
          <button 
            class="navbar-toggler" 
            type="button" 
            data-bs-toggle="offcanvas" 
            data-bs-target="#offcanvasNavbar" 
            aria-controls="offcanvasNavbar" 
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end" 
            tabindex="-1" 
            id="offcanvasNavbar" 
            aria-labelledby="offcanvasNavbarLabel"
            >
            <div class="offcanvas-header">
              <div class="d-flex align-items-center">
                <img 
                class="img-fluid col-1 me-3" 
                src="../assets/img/logoOrigBPC.webp" 
                alt=""
                >
                <h4 class="offcanvas-title fw-bold" id="offcanvasNavbarLabel">Компания</h4>
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav me-auto my-2 my-lg-0">
                <li class="nav-item">
                  <h5>
                    <router-link class="nav-link active" to="AboutUs">
                      {{ $t('header.title1') }}
                    </router-link>
                  </h5>
                </li>
                <li class="nav-item">
                  <h5>
                    <router-link class="nav-link" to="ProductsPage">
                      {{ $t('header.title2') }}
                    </router-link>
                  </h5>
                </li>
                <li class="nav-item">
                  <h5>
                    <router-link class="nav-link" to="Services">
                      {{ $t('header.title3') }}
                    </router-link>
                    </h5>
                </li>
                <li class="nav-item">
                  <h5>
                    <router-link class="nav-link" to="ProjectsPage">
                      {{ $t('header.title4') }}
                    </router-link>
                  </h5>
                </li>
                <li class="nav-item">
                  <h5>
                    <router-link class="nav-link " to="OurWork">
                      {{ $t('header.title5') }}
                    </router-link>
                  </h5>
                </li>
              </ul>
              <div className="d-flex align-items-center justify-content-evenly">
                <!-- <select class="form-select" v-model="select">
                  <option value="ru">ru</option>
                  <option value="kaz">kaz</option>
                  <option value="en">en</option>
                </select> -->
                <div class="border rounded-2 ms-4 px-4 py-1" id="lang" @click="switchLang">ru</div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  </div>
 
</template>
<script setup>
import { useI18n } from 'vue-i18n'
/* eslint-disable */
const { t, locale} = useI18n({useScope: 'global'})

const switchLang = () => {
  // locale.value ==='ru' ? locale.value = 'kaz' : locale.value === 'kaz' ? locale.value = 'en' : locale.value = 'ru' //
  localStorage.setItem('lang', locale.value)
}
</script>

